export const InstantConfirmation = () => {
    return (
        <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5661 4.67941C14.8377 4.78843 15.003 5.0655 14.9698 5.35626L14.5109 9.38044H17.7304C17.7378 9.38044 17.7453 9.38044 17.7528 9.38044C17.8802 9.38039 18.0197 9.38035 18.1357 9.39186C18.2486 9.40306 18.4804 9.43459 18.6783 9.60983C18.8932 9.80024 19.0014 10.0721 18.9977 10.3406C18.9944 10.5803 18.8871 10.7656 18.8257 10.8631C18.7627 10.963 18.6765 11.0763 18.5936 11.1854L13.7587 17.5455C13.5816 17.7785 13.2706 17.8644 12.9991 17.7554C12.7275 17.6464 12.5622 17.3693 12.5954 17.0785L13.0543 13.0544H9.83484C9.8274 13.0544 9.81991 13.0544 9.81237 13.0544C9.68496 13.0544 9.54547 13.0544 9.42949 13.0429C9.31661 13.0317 9.08475 13.0002 8.88692 12.825C8.67198 12.6346 8.56378 12.3627 8.56747 12.0942C8.57077 11.8545 8.67814 11.6692 8.73955 11.5717C8.80252 11.4717 8.88868 11.3585 8.97164 11.2494C8.97599 11.2437 8.98033 11.2379 8.98466 11.2323L13.8065 4.88925C13.9836 4.65628 14.2946 4.57038 14.5661 4.67941ZM10.2207 11.7544H13.7826C13.9678 11.7544 14.1442 11.8334 14.2676 11.9716C14.3909 12.1097 14.4494 12.294 14.4284 12.478L14.155 14.8761L17.3445 10.6804H13.7826C13.5974 10.6804 13.421 10.6014 13.2976 10.4632C13.1743 10.3251 13.1158 10.1408 13.1368 9.9568L13.4102 7.55868L10.2207 11.7544Z"
                fill="var(--svg-gray1-white)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7827 3.65001C11.4057 3.65001 9.28502 4.74515 7.89664 6.46088C7.67083 6.73995 7.26154 6.78311 6.98247 6.55729C6.70341 6.33148 6.66025 5.92219 6.88607 5.64313C8.51065 3.63547 10.9968 2.35001 13.7827 2.35001C18.68 2.35001 22.6501 6.32007 22.6501 11.2174C22.6501 16.1147 18.68 20.0848 13.7827 20.0848C10.9968 20.0848 8.51065 18.7993 6.88607 16.7917C6.66025 16.5126 6.70341 16.1033 6.98247 15.8775C7.26154 15.6517 7.67083 15.6948 7.89664 15.9739C9.28502 17.6896 11.4057 18.7848 13.7827 18.7848C17.962 18.7848 21.3501 15.3968 21.3501 11.2174C21.3501 7.03804 17.962 3.65001 13.7827 3.65001Z"
                fill="var(--svg-gray1-white)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.65002 11.2174C6.65002 11.5764 6.35901 11.8674 6.00002 11.8674H1.00002C0.641039 11.8674 0.350024 11.5764 0.350024 11.2174C0.350024 10.8584 0.641039 10.5674 1.00002 10.5674H6.00002C6.35901 10.5674 6.65002 10.8584 6.65002 11.2174Z"
                fill="var(--svg-gray1-white)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.58484 7.79998C7.58484 8.15897 7.29382 8.44998 6.93484 8.44998H3.28266C2.92368 8.44998 2.63267 8.15897 2.63267 7.79998C2.63267 7.441 2.92368 7.14998 3.28266 7.14998H6.93484C7.29382 7.14998 7.58484 7.441 7.58484 7.79998Z"
                fill="var(--svg-gray1-white)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.58484 14.5C7.58484 14.859 7.29382 15.15 6.93484 15.15H3.28266C2.92368 15.15 2.63267 14.859 2.63267 14.5C2.63267 14.141 2.92368 13.85 3.28266 13.85H6.93484C7.29382 13.85 7.58484 14.141 7.58484 14.5Z"
                fill="var(--svg-gray1-white)"
            />
        </svg>
    );
};
