export const ACTIVITY_TABS = [
    { title: 'Activity Highlights', key: 'highlights', id: 'highlights' },
    { title: 'Departure', key: 'departure', id: 'departure' },
    // { title: 'Safety Precautions', key: 'pandemic_meta_data', id: 'pandemic_meta_data' },
    { title: 'Menu', key: 'expectations', id: 'expectations' },
    { title: "What's Included", key: 'included', id: 'included' },
    { title: 'What to bring', key: 'excluded', id: 'excluded' },
    { title: 'Restrictions', key: 'restrictions', id: 'restrictions' },
    { title: 'Additional Information', key: 'additional', id: 'additional' },
    { title: 'Reservation Requirements', key: 'agreement', id: 'agreement' },
    { title: 'Location', key: 'location', id: 'location' },
];
