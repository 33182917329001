import { ReactElement, ReactNode, useEffect, useState } from 'react';
import * as S from './RatesWrapper.styles';
import { TRate } from '@/common/service/api/Availability/Availability.domain';
import { Button } from '@/common/ui';
import { useActivityContext } from '@/common/app/contexts/ActivityContext';

const DEFAULT_RATES_COUNT = 3;

type RatesWrapperProps = {
    children: ReactNode;
    rates: TRate[];
    selectedId: number;
    selectRate: (newRateId: number) => void;
};

export const RatesWrapper = ({
    children,
    rates,
    selectedId,
    selectRate,
}: RatesWrapperProps): ReactElement => {
    const [showMore, setShowMore] = useState(rates.length <= DEFAULT_RATES_COUNT);
    const [indexOpen, setIndexOpen] = useState<number | null>(0);
    const { isValidRate, availability } = useActivityContext();

    const availableRates = rates.filter((r) => r.is_avail || r.rate_id);

    const handleClickOnTab = (index: number) => {
        if (index === indexOpen) {
            setIndexOpen(index + 1);
        }
    };

    useEffect(() => {
        const idxRate = availableRates.findIndex((r) => r.rate_id === selectedId);
        setShowMore(availableRates.length <= DEFAULT_RATES_COUNT || idxRate > 2);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rates]);

    if (!+availability.use_rates) {
        return <>{children}</>;
    }

    const scrollToBottom = () => {
        document.getElementById('cancellation-info')?.scrollIntoView({ behavior: 'auto' });
    };

    const ratesList = showMore ? availableRates : availableRates.slice(0, DEFAULT_RATES_COUNT);

    return (
        <>
            {ratesList.map(({ rate_id, rate_name, is_avail }, index) => {
                const isActive = rate_id === selectedId;
                return (
                    <S.Container
                        isActive={isActive}
                        key={rate_id}
                        onClick={() => !isActive && is_avail && selectRate(rate_id)}
                    >
                        <S.RateName isActive={isActive} onClick={() => handleClickOnTab(index)}>
                            {rate_name}
                            {is_avail ? (
                                <S.Arrow isActive={isActive} />
                            ) : (
                                <S.SoldoutLabel>SOLD OUT</S.SoldoutLabel>
                            )}
                        </S.RateName>
                        {isActive && children}
                        {is_avail && (
                            <S.ButtonWrapper isActive={isActive}>
                                <Button
                                    variant="primary"
                                    onClick={scrollToBottom}
                                    disabled={!isValidRate}
                                >
                                    Continue
                                </Button>
                            </S.ButtonWrapper>
                        )}
                    </S.Container>
                );
            })}
            {!showMore && (
                <S.ShowMore onClick={() => setShowMore(true)}>
                    See {availableRates.length - DEFAULT_RATES_COUNT} More
                </S.ShowMore>
            )}
        </>
    );
};
