import styled, { css } from 'styled-components';
import { cssVars } from '@/styles/Themes/cssVars';
import Text from 'src/styles/Themes/Text';

import Screen from '@/styles/Themes/Screen';
import { CalendarIcon } from '@/shared/images/icons/CalendarIcon';
import { borders, radius } from '@/styles/Themes/Metrics';
import { DayPicker } from '../DayPicker/DayPicker';

export const Wrapper = styled.div<{ isNotSearch?: boolean }>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    ${({ isNotSearch }) =>
        !isNotSearch &&
        css`
            border: ${borders.small} ${cssVars.fg_gray3_dark1};
            border-radius: ${radius.large};
            margin-bottom: 16px;
        `}

    ${Screen.desktop} {
        margin: 0;
        border: none;
        border-radius: 0;
        flex: 1;
        ${({ isNotSearch }) =>
            !isNotSearch &&
            css`
                background-color: ${cssVars.bg_white_blue};
            `}
    }
`;

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 50px;

    ${Screen.desktop} {
        height: 60px;
    }
`;

export const Label = styled.label`
    ${Text.Desktop.normal.regular};
    color: ${cssVars.fg_gray2_gray3};
`;

export const Placeholder = styled.div`
    width: 100%;
    ${Text.Desktop.large.medium};
    color: ${cssVars.fg_gray1_white};
    border: none;
    background: transparent;

    &:focus-visible {
        outline: none;
        appearance: none;
    }
`;

export const Icons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-left: 5px;
    flex: none;
`;

export const ButtonArrow = styled.div`
    cursor: pointer;
    display: block;
`;

export const IconCalendar = styled(CalendarIcon)`
    display: block;
`;

export const ResultsContainer = styled.div<{ isNotSearch?: boolean }>`
    margin: 0 auto;
    width: 309px;
    height: fit-content;

    ${Screen.desktop} {
        position: absolute;
        padding: 10px 20px;
        background-color: ${cssVars.bg_white_dark3};
        box-shadow: 0px 4px 23px ${cssVars.shadow_normal};
        z-index: 1;
        border-radius: ${radius.small};
        left: ${({ isNotSearch }) => (isNotSearch ? '-88px' : 0)};
        top: 62px;
    }
`;

export const InputDate = styled(DayPicker)`
    width: 100%;
    height: 100%;
    background-color: ${cssVars.bg_white_blue};
`;

export const ChildrenContainer = styled.div`
    display: flex;
    align-items: center;
    text-decoration: underline;

    ${Screen.desktop} {
        text-decoration: none;
    }
`;

export const Gradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 31.6%, rgba(0, 0, 0, 0.168) 73.45%);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
