import styled, { css } from 'styled-components';
import Screen from '@/styles/Themes/Screen';

export const RadioContainer = styled.div<{ isDropdown: boolean }>`
    display: grid;
    grid-gap: 24px;
    padding: 0 16px;
    ${({ isDropdown }) =>
        isDropdown
            ? css`
                  width: 100%;
              `
            : css`
                  align-items: flex-start;
              `};

    ${Screen.ipad} {
        padding: 0 20px;
    }

    ${Screen.desktop} {
        max-width: ${({ isDropdown }) => (isDropdown ? '50%' : 'auto')};
        padding-left: 30px;
    }
`;
