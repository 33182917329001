import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import Screen from '@/styles/Themes/Screen';
import { LeftArrow, RightArrow } from '@/common/ui';
import { radius } from '@/styles/Themes/Metrics';
import { Transition } from '@/styles/Themes/KeyFrames';
import Palette from '@/styles/Themes/Palette';
import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';

export const Title = styled.h2`
    text-align: left;
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.heading2};
    padding: 12px 0 0;
    margin: 0;

    ${Screen.ipad} {
        padding: 41px 0 0;
    }

    ${Screen.desktop} {
        padding: 47px 0 0;
    }
`;

export const CenterLeftArrow = styled(LeftArrow)`
    z-index: 2;
    position: absolute;
    left: 5px;
    top: 45%;
`;

export const CenterRightArrow = styled(RightArrow)`
    z-index: 2;
    position: absolute;
    right: 5px;
    top: 45%;
`;

export const Gallery = styled.div`
    display: flex;
    user-select: none;

    overflow-x: auto;
    scroll-behavior: smooth;
    transition: 0.5s ease 0s;
    box-sizing: border-box;

    ::-webkit-scrollbar {
        display: none;
    }

    width: calc(100% + 32px);
    padding: 16px;
    transform: translateX(-16px);

    ${Screen.ipad} {
        width: calc(100% + 48px);
        padding: 24px;
        transform: translateX(-24px);
    }
`;

export const InnerContainer = styled.div`
    gap: 24px;
    transition: 0.5s ease 0s;
    width: auto;
    display: flex;
`;

export const CarouselWraper = styled.div`
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: calc(100% + 32px);
    padding: 0 16px;
    transform: translateX(-16px);

    ${Screen.ipad} {
        width: calc(100% + 48px);
        padding: 0 24px;
        transform: translateX(-24px);
    }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(to left, transparent 0%, ${cssVars.bg_white_blue} 100%);
        background-image: -webkit-linear-gradient(
            0deg,
            ${cssVars.transparent_white_blue} 0%,
            ${cssVars.bg_white_blue} 100%
        );
        width: 16px;
        z-index: 1;
        opacity: 0.9;
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(to right, transparent 0%, ${cssVars.bg_white_blue} 100%);
        background-image: -webkit-linear-gradient(
            180deg,
            ${cssVars.transparent_white_blue} 0%,
            ${cssVars.bg_white_blue} 100%
        );
        width: 16px;
        z-index: 1;
        opacity: 0.9;
    }
`;

export const CardContainer = styled.div<{ numberOfItems: number }>`
    display: flex;
    width: ${({ numberOfItems }) => (numberOfItems === 1 ? '100%' : '316px')};
    height: 95px;
    min-width: 316px;
    position: relative;
    flex-direction: row;
    padding: 10px;
    border-radius: ${radius.small};
    background-color: ${cssVars.bg_white_dark3};
    ${Transition.normal};
    gap: 9px;
    box-shadow: ${cssVars.shadow_card_hover};

    ${Screen.ipad} {
        min-width: auto;
        width: ${({ numberOfItems }) => (numberOfItems === 2 ? '344px' : '340px')};
    }
    ${Screen.desktop} {
        width: 248px;
    }
`;

export const CardWidth = styled.a`
    display: block;
    gap: 12px;

    ${Screen.mobile} {
        width: 100%;
    }
`;

export const Image = styled.img`
    width: 74px;
    object-fit: cover;
    border-radius: ${radius.small};
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LandmarkTitle = styled.span`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.large.semibold};
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    overflow: hidden;
`;

export const ReadMore = styled.span`
    ${Text.Full.medium.medium};
    color: ${Palette.green.normal};
    display: flex;
    align-items: center;
    margin-top: auto;
`;

export const Arrow = styled(DropdownArrow)`
    margin-left: 6px;
    fill: ${Palette.green.normal};
    transform: rotate(-90deg);
    ${Transition.normal};
`;

export const DotsSlider = styled.div`
    flex-direction: row;
    justify-content: center;
    align-items: end;
    display: none;

    ${Screen.desktop} {
        display: flex;
    }
`;
