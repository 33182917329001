import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';

export const WrapperCreateYourBooking = styled.div``;

export const OptionGrid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    align-items: flex-start;
    padding: 0 16px;

    ${Screen.ipad} {
        padding: 0 19px;
        grid-gap: 22px;
    }

    ${Screen.desktop} {
        max-width: 50%;
        padding-left: 30px;
    }
`;

export const Subtitle = styled.span`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.large.semibold}
    padding: 27px 0 10px;
    display: block;

    ${Screen.ipad} {
        padding: 27px 0 13px;
    }
`;

export const SubtitleNote = styled.span`
    color: ${Palette.gray.gray3};
`;
