import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FeaturedActivitiesPreview } from './featuredActivitiesPreview/FeaturedActivitiesPreview';
import * as S from './FeaturedActivities.styles';
import { useCarousel } from '@/common/app/hooks/useCarousel';
import { IRecommendsItem } from './featuredActivitiesPreview/FeaturedActivitiesPreview.types';
import { FeaturedActivitiesSkeleton } from './FeaturedActivitiesSkeleton/FeaturedActivitiesSkeleton';
import {
    IDestinationCategory,
    IDestinationList,
} from '@/entities/Destination/domain/Destination.domain';
import { useDateContext } from '@/common/app/contexts';
import { getRecommends } from '../service/ApiFeaturedActivities';
import { CreateParams, splitAvailAndSoldout } from '@/entities/Attractions/service/Creators';
import { getAvailabilityShortByIds } from '@/entities/Attractions/service/ApiAttractionsPage';
import { mergeAvailability } from '@/common/app/utils/mergeAvailability';
import { IActivityItem } from '@/common/service/api/Activity/Activity.domain';
import { IActivityWithAvailability } from '@/common/domain/Merge.domain';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';

type Tabs = 'ALSO_VIEWED' | 'RECOMMENDS';

export type AlsoViewedActivitiesProps = {
    currentActivity: IActivity;
    alsoViewedActivities: IActivityItem[];
    currentDestination: IDestinationList;
    currentCategory: IDestinationCategory | null;
};

const AlsoViewedActivitiesContainer = ({
    currentActivity,
    currentCategory,
    currentDestination,
    alsoViewedActivities,
}: AlsoViewedActivitiesProps) => {
    const { date } = useDateContext();
    const scrollRef = useRef<HTMLDivElement>(null);

    const [activeTab, setActiveTab] = useState<Tabs>('ALSO_VIEWED');
    const [recommends, setRecommends] = useState<IRecommendsItem[]>([]);
    const [activities, setActivities] = useState<IActivityWithAvailability[]>([]);

    const [isRecommendsLoading, setIsRecommendsLoading] = useState<boolean>(true);
    const [isAvailabilityLoading, setIsAvailabilityLoading] = useState<boolean>(true);

    const {
        prevBtnIsActive,
        nextBtnIsActive,
        onClickHandler,
        onScrollHandler,
        DIRECTION,
        withDot,
    } = useCarousel({
        ref: scrollRef,
        countItems: activeTab === 'ALSO_VIEWED' ? activities.length : recommends.length,
    });

    const fetchAvailability = useCallback(async () => {
        try {
            const [activity_ids, params] = CreateParams(alsoViewedActivities, date);
            const availability = await getAvailabilityShortByIds(activity_ids, params);

            const activitiesWithAvailability = mergeAvailability(
                alsoViewedActivities,
                availability
            );
            const { available } = splitAvailAndSoldout(activitiesWithAvailability);

            setActivities(available);
        } catch (error) {
            setActivities([]);
        } finally {
            setIsAvailabilityLoading(false);
        }
    }, [alsoViewedActivities, date]);

    const fetchRecommends = useCallback(async () => {
        try {
            if (!date) {
                setRecommends([]);
                return setIsRecommendsLoading(false);
            }

            const resRecommends = await getRecommends({
                destination_id: currentDestination.id,
                category_id: currentCategory?.id,
                from: date.from,
                to: date.to,
            });

            setRecommends(
                resRecommends.filter(
                    (recommendActivity) => recommendActivity.id !== currentActivity.id
                )
            );
        } catch (error) {
            return setRecommends([]);
        } finally {
            setIsRecommendsLoading(false);
        }
    }, [currentActivity.id, currentCategory?.id, currentDestination.id, date]);

    useEffect(() => {
        fetchAvailability();
        fetchRecommends();
    }, [fetchAvailability, fetchRecommends]);

    const onTabClick = (tab: Tabs) => {
        setActiveTab(tab);
    };

    const RendererGallery = useMemo(() => {
        if (
            (activeTab === 'ALSO_VIEWED' && isAvailabilityLoading) ||
            (activeTab === 'RECOMMENDS' && isRecommendsLoading)
        ) {
            return (
                <S.Gallery ref={scrollRef} onScroll={onScrollHandler}>
                    <S.InnerContainer className="carousel-container">
                        {Array.from(new Array(4)).map((_, index) => (
                            <FeaturedActivitiesSkeleton key={index} />
                        ))}
                    </S.InnerContainer>
                </S.Gallery>
            );
        }

        const item_list_id = typeof window !== 'undefined' ? window.location.pathname : '';

        if (activeTab === 'ALSO_VIEWED') {
            return (
                <S.Gallery ref={scrollRef} onScroll={onScrollHandler}>
                    <S.InnerContainer className="carousel-container">
                        {activities.map((slide, index) => (
                            <FeaturedActivitiesPreview
                                {...(slide as unknown as IRecommendsItem)}
                                numberOfItems={activities.length}
                                position={index}
                                isFeaturedList={false}
                                key={slide.id}
                                listName={`Featured ${currentCategory?.name} Activities`}
                                item_list_id={item_list_id}
                                item_list_name={'Travelers Also Viewed'}
                            />
                        ))}
                    </S.InnerContainer>
                </S.Gallery>
            );
        }

        if (activeTab === 'RECOMMENDS') {
            return (
                <S.Gallery ref={scrollRef} onScroll={onScrollHandler}>
                    <S.InnerContainer className="carousel-container">
                        {recommends.map((slide, index) => (
                            <FeaturedActivitiesPreview
                                {...(slide as unknown as IRecommendsItem)}
                                numberOfItems={activities.length}
                                position={index}
                                isFeaturedList={true}
                                key={slide.id}
                                listName=""
                                item_list_id={item_list_id}
                                item_list_name={`Featured ${currentCategory?.name} Activities`}
                            />
                        ))}
                    </S.InnerContainer>
                </S.Gallery>
            );
        }

        return <></>;
    }, [
        activeTab,
        activities,
        currentCategory?.name,
        isAvailabilityLoading,
        isRecommendsLoading,
        onScrollHandler,
        recommends,
    ]);

    if (!isAvailabilityLoading && !activities.length) {
        return null;
    }

    return (
        <S.AlsoViewedWrapper>
            <S.Block data-test-id="Widget-attraction" className="container">
                <S.AlsoViewedHeader>
                    <S.LeftPartHeader>
                        <S.TabsWrapper>
                            <S.Tab
                                isActive={activeTab === 'ALSO_VIEWED'}
                                onClick={() => onTabClick('ALSO_VIEWED')}
                            >
                                <span>Travelers Also Viewed</span>
                            </S.Tab>
                            {!!recommends.length && (
                                <>
                                    <S.TabDivider />
                                    <S.Tab
                                        isActive={activeTab === 'RECOMMENDS'}
                                        onClick={() => onTabClick('RECOMMENDS')}
                                    >
                                        <span>Featured {currentCategory?.name} Activities</span>
                                    </S.Tab>
                                </>
                            )}
                        </S.TabsWrapper>
                    </S.LeftPartHeader>
                </S.AlsoViewedHeader>
                <S.CarouselWraper>
                    {RendererGallery}
                    {prevBtnIsActive && withDot && (
                        <S.CenterLeftArrow
                            onClick={() => onClickHandler(DIRECTION.prev)}
                            disabled={false}
                        />
                    )}
                    {nextBtnIsActive && withDot && (
                        <S.CenterRightArrow
                            onClick={() => onClickHandler(DIRECTION.next)}
                            disabled={false}
                        />
                    )}
                </S.CarouselWraper>
            </S.Block>
        </S.AlsoViewedWrapper>
    );
};

export const AlsoViewedActivities = memo(AlsoViewedActivitiesContainer);
