import { LowestPrice } from '@/shared/images/icons/LowestPrice';
// import { Confidence } from '@/shared/images/icons/Confidence';
import { Cancellation } from '@/shared/images/icons/Cancellation';
import { ActivityDescription } from './ActivityDescription/ActivityDescription';
import { RedHeartIcon } from '@/shared/images/icons/RedHeartIcon';
import * as S from './ActivityDetail.styles';
import { TImageItem } from '@/common/service/api/Activity/Activity.domain';
import { ImagesGallery } from './ImagesGallery/ImagesGallery';
import { IVideo } from '@/entities/Activity/domain/Activity.domain';
import { useActivityContext } from '@/common/app/contexts/ActivityContext';
import { getNormalisedPrice } from '@/common/app/utils/getNormalisedPrice';
import { LoadMore } from '@/common/ui/loaders/LoadMore/LoadMore';
//import { getWpage } from '@/common/app/utils/iterable';
import { InstantConfirmation } from '@/shared/images/icons/InstantConfirmation';
// import { IfFeatureEnabled } from '@growthbook/growthbook-react';

type ActivityDetailProps = {
    videos: IVideo[];
    slug: string;
    name: string;
    rootUrl: string;
    activityId: string;
    userImages: TImageItem[];
    addedToWishList: boolean;
    onGetTickets: () => void;
    onWishlist: () => void;
    activityStrikeOut?: number;
    defaultPrice?: number;
    isGiftCard: boolean;
    totalPurchased?: string;
    recentPurchased?: string;
};

export const ActivityDetail = ({
    videos,
    slug,
    name,
    activityId,
    userImages,
    addedToWishList,
    onWishlist,
    onGetTickets,
    isGiftCard,
    defaultPrice,
    totalPurchased,
    recentPurchased,
    rootUrl,
}: ActivityDetailProps) => {
    const { shortAvail } = useActivityContext();

    const priceWithComma = shortAvail?.price
        ? getNormalisedPrice(shortAvail.price)
        : getNormalisedPrice(defaultPrice || 0);
    const priceStrikeOutWithComma =
        shortAvail?.price_strike_out &&
        shortAvail?.price &&
        shortAvail.price_strike_out > shortAvail.price
            ? getNormalisedPrice(shortAvail?.price_strike_out)
            : 0;

    const onSharePage = async () => {
        if (
            typeof window === 'undefined' ||
            typeof navigator === 'undefined' ||
            !navigator.canShare
        ) {
            return;
        }
        try {
            await navigator.share({
                url: rootUrl,
                text: `Check out the trip ${name} with prices starting from ${priceWithComma}!`,
            });
        } catch (error) {
            console.log('err: navigator cant share');
        }
    };

    return (
        <S.ActivityDetailContainer data-test-id="Details_gallery">
            <S.GalleryWrapper>
                <ImagesGallery
                    userImages={userImages}
                    activityId={activityId}
                    activitySlug={slug}
                    videos={videos}
                    totalPurchased={totalPurchased}
                    recentPurchased={recentPurchased}
                    eagerMore
                />
            </S.GalleryWrapper>
            <S.InfoContainer>
                <S.PriceContainer data-test-id="Details_price">
                    {shortAvail ? (
                        <>
                            {priceWithComma ? (
                                <>
                                    From&nbsp;
                                    {!!priceStrikeOutWithComma && (
                                        <S.StrikeOut>{priceStrikeOutWithComma}</S.StrikeOut>
                                    )}
                                    &nbsp;
                                    <S.Price>{priceWithComma}</S.Price>
                                </>
                            ) : (
                                <S.Price>Price varies</S.Price>
                            )}
                        </>
                    ) : (
                        <LoadMore isLoading noMargin />
                    )}
                </S.PriceContainer>
                <S.GetTicketsButton
                    variant="primary"
                    onClick={onGetTickets}
                    data-test-id="Get_tickets_btn"
                >
                    {isGiftCard ? 'Purchase' : 'Get Tickets'}
                </S.GetTicketsButton>
                <S.Divider />
                <S.DescriptionContainer>
                    <ActivityDescription
                        icon={<LowestPrice />}
                        title="Lowest Price Guarantee"
                        description={`You won't find a lower price`}
                    />

                    {/* <IfFeatureEnabled feature="paypal-on">
                        <ActivityDescription
                            icon={<Confidence />}
                            title="Buy Now, Pay Over Time"
                            description={`Finance with PayPal Credit`}
                        />
                    </IfFeatureEnabled> */}
                    <ActivityDescription
                        icon={<Cancellation />}
                        title="Flexible Cancellation"
                        description={`Cancel anytime with TripFlex`}
                    />
                    <ActivityDescription
                        icon={<InstantConfirmation />}
                        title="Instant Confirmation"
                        description={`Bookings are confirmed in real-time`}
                    />
                </S.DescriptionContainer>
                <S.Divider />
                <S.ActivityActions>
                    <S.WishlistButton
                        variant="none"
                        onClick={onWishlist}
                        data-test-id="Details_wishlist_btn"
                    >
                        <S.RedHeartWrapper>
                            {addedToWishList ? <S.WishListBtnFilled /> : <RedHeartIcon />}
                        </S.RedHeartWrapper>
                        <span>{addedToWishList ? `In your Wishlist` : `Add to Wishlist`}</span>
                    </S.WishlistButton>
                    <S.ShareButton
                        variant="none"
                        onClick={onSharePage}
                        data-test-id="Share_activity_btn"
                    >
                        <S.ShareButtonWrapper>
                            <S.IconButtonShare />
                        </S.ShareButtonWrapper>
                        Share
                        {/* <S.ShareDropdown isOpen={openShareModal} ref={ref}>
                            <S.ShareDropdownItem>
                                <ChatShareIcon /> Text
                            </S.ShareDropdownItem>
                            <S.ShareDropdownItem>
                                <EmailShareIcon /> Email
                            </S.ShareDropdownItem>
                            <S.ShareDropdownItem>
                                <LinkShareIcon /> Copy link
                            </S.ShareDropdownItem>
                        </S.ShareDropdown> */}
                    </S.ShareButton>
                </S.ActivityActions>
            </S.InfoContainer>
        </S.ActivityDetailContainer>
    );
};
