import { ReactElement } from 'react';
import * as S from './Paragraph.styles';
import { TParapgrah, TRestProps } from './Paragraph.types';

const Paragraph = ({
    children,
    innerHtml,
    marginBottom,
    useInitialStyle,
    id,
    className,
    as,
}: TParapgrah): ReactElement => {
    const restProps: TRestProps = {};

    if (innerHtml) {
        restProps.dangerouslySetInnerHTML = {
            __html: innerHtml.toString(),
        };
    }

    const styledMarginBottom =
        marginBottom !== null
            ? typeof marginBottom === 'string'
                ? marginBottom
                : `${marginBottom}px`
            : false;
    return (
        <S.Paragraph
            data-testid="Paragraph-component"
            id={id}
            useInitialStyle={useInitialStyle}
            marginBottom={styledMarginBottom}
            className={className}
            data-test-id="paragraph"
            as={as as never}
            {...restProps}
        >
            {children}
        </S.Paragraph>
    );
};

export default Paragraph;
