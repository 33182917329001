import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';

export const Container = styled.div`
    background-color: ${cssVars.bg_white_dark3};
    border-radius: 0 0 15px 15px;
    padding: 50px 18px;

    ${Screen.ipad} {
        padding: 50px 30px;
    }
    ${Screen.desktop} {
        padding: 60px 48px 50px;
    }
`;

export const NextAvail = styled.div`
    ${Text.Full.medium.medium}
    color: ${cssVars.fg_gray2_gray3};
    border-top: 1px solid ${cssVars.bg_gray4_dark2};
    padding-top: 40px;
    margin-top: 40px;

    ${Screen.desktop} {
        margin-top: 60px;
    }
`;

export const DesktopText = styled.p`
    ${Text.Full.medium.medium}
    color: ${cssVars.fg_gray2_gray3};
    margin-bottom: 40px;

    ${Screen.mobile} {
        display: none;
    }
`;

export const BoldText = styled.span`
    ${Text.Full.large.medium}
    color: ${cssVars.fg_gray1_white};
`;

export const LinkText = styled.a`
    ${Text.Full.large.semibold}
    color: ${Palette.state.info};
    cursor: pointer;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

export const MobileTitle = styled.div`
    ${Text.Full.large.semibold}
    color: ${cssVars.fg_gray1_white};
    background-color: ${cssVars.bg_gray4_dark2};
    padding: 16px 0;
    margin-bottom: 20px;
    text-align: center;
    border-radius: ${radius.small};
`;

export const MobileText = styled.div`
    ${Text.Full.large.medium}
    color: ${cssVars.fg_gray2_gray3};

    ${Screen.ipad} {
        display: none;
    }
`;
