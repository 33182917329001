import { TLandmarkShort } from '@/screens/LandmarkMainPage/domain/Landmarks.domain';
import * as S from './LandmarksCarousel.styles';
import { useRef } from 'react';
import { useCarousel } from '@/common/app/hooks/useCarousel';
import Link from 'next/link';
import URL from '@/common/app/utils/urlsUtils';
import { IDestinationList } from '@/entities/Destination/domain/Destination.domain';

type LandmarksCarouselProps = {
    landmarks: TLandmarkShort[];
    currentDestination: IDestinationList;
};

export const LandmarksCarousel = ({ landmarks, currentDestination }: LandmarksCarouselProps) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    const {
        prevBtnIsActive,
        nextBtnIsActive,
        onClickHandler,
        onScrollHandler,
        DIRECTION,
        withDot,
    } = useCarousel({
        ref: scrollRef,
        countItems: landmarks.length,
    });

    return (
        <div className="container">
            <S.Title>Location Youʹll Explore</S.Title>
            <S.CarouselWraper>
                <S.Gallery ref={scrollRef} onScroll={onScrollHandler}>
                    <S.InnerContainer className="carousel-container">
                        {landmarks.map((landmark, index) => (
                            <Link
                                key={index}
                                href={`/destination/${currentDestination.state.code}/${currentDestination.slug}/landmarks/${landmark.slug}/`.toLowerCase()}
                                passHref
                                prefetch={false}
                            >
                                <S.CardWidth className="card">
                                    <S.CardContainer numberOfItems={landmarks.length}>
                                        <S.Image
                                            src={`${URL.image}/landmark/${landmark.id}/700x600/${landmark.slug}.jpg`}
                                            alt={landmark.name}
                                        />
                                        <S.CardContent>
                                            <S.LandmarkTitle>{landmark.name}</S.LandmarkTitle>
                                            <S.ReadMore>
                                                Read more <S.Arrow />
                                            </S.ReadMore>
                                        </S.CardContent>
                                    </S.CardContainer>
                                </S.CardWidth>
                            </Link>
                        ))}
                    </S.InnerContainer>
                </S.Gallery>
                {prevBtnIsActive && withDot && (
                    <S.CenterLeftArrow
                        onClick={() => onClickHandler(DIRECTION.prev)}
                        disabled={false}
                    />
                )}
                {nextBtnIsActive && withDot && (
                    <S.CenterRightArrow
                        onClick={() => onClickHandler(DIRECTION.next)}
                        disabled={false}
                    />
                )}
            </S.CarouselWraper>
        </div>
    );
};
