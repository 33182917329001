import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import { Button } from '@/common/ui';
import { borders, radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import { HeartIcon } from '@/shared/images/icons/HeartIcon';
import Screen from '@/styles/Themes/Screen';
import { ArrowLeftCard } from '@/shared/images/icons/ArrowLeftCard';
import { HeartIconFilled } from '@/shared/images/icons/HeartIconFilled';
import { ShareButtonIcon } from '@/shared/images/icons/ShareButtonIcon';

export const ActivityDetailContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    ${Screen.desktop} {
        gap: 24px;
        flex-direction: row;
    }
`;

export const GalleryWrapper = styled.div`
    flex: 1 1 0%;

    ${Screen.desktop} {
        height: 480px;
    }
`;

export const ImageContainer = styled.div`
    border-radius: ${radius.small};
    box-shadow: ${cssVars.shadow_card_default};
    padding: 20px;
    width: 100%;
    height: 280px;
    ${Screen.desktop} {
        width: 690px;
        height: 489px;
    }
`;

export const Gradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
        180deg,
        rgba(20, 19, 19, 0) 31.6%,
        rgba(0, 0, 0, 0.052) 73.45%
    );
`;

export const Image = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;

    height: 254px;
    ${Screen.ipad} {
        height: 355px;
    }
    ${Screen.desktop} {
        height: 288px;
    }
`;

export const CenterRightArrow = styled(ArrowLeftCard)`
    position: absolute;
    transform: rotate(180deg);
    height: 30px;
    width: 30px;
    right: 5px;
    top: 45%;

    ${Screen.ipad} {
        height: 36px;
        width: 36px;
    }
    ${Screen.desktop} {
        opacity: 0;
    }
`;

export const CenterLeftArrow = styled(ArrowLeftCard)`
    position: absolute;
    height: 30px;
    width: 30px;
    left: 5px;
    top: 45%;

    ${Screen.ipad} {
        height: 36px;
        width: 36px;
    }
    ${Screen.desktop} {
        opacity: 0;
    }
`;

export const InfoContainer = styled.div`
    background-color: ${cssVars.bg_white_blue};
    border-radius: ${radius.small};
    box-shadow: ${cssVars.shadow_card_default};
    padding: 16px 20px 20px;
    margin: 16px 0 0;
    width: 100%;

    ${Screen.desktop} {
        max-width: 340px;
        height: 480px;
        padding: 24px;
        margin: 0;
    }
`;

export const PriceContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${Text.Full.heading3};
    color: ${cssVars.fg_gray2_gray3};
    margin-bottom: 16px;
    min-height: 42px;
`;

export const GetTicketsButton = styled(Button)`
    padding: 0;
    width: 100%;
    height: 50px;
`;

export const DescriptionContainer = styled.div`
    display: grid;
    align-items: center;
    width: 90%;
    grid-gap: 25px;
`;

export const ActivityActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const WishlistButton = styled(Button)`
    padding: 0;
    color: ${Palette.state.error};
    ${Text.Mobile.medium.medium};
    border: none;
    justify-content: flex-start;
    &:hover {
        border: none;
    }
    &:active {
        border: none;
    }

    ${Screen.desktop} {
        justify-content: flex-start;
        height: 50px;
        ${Text.Desktop.normal.medium};
    }
`;

export const ShareButtonWrapper = styled.div`
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${cssVars.bg_white_blue};
    border: ${borders.small} ${cssVars.bg_ash_darker};
    border-radius: ${radius.large};
    box-shadow: ${cssVars.shadow_card_default};
    margin-right: 8px;
    > svg {
        height: 17px;
        width: 17px;
    }
`;

export const ShareButton = styled(Button)`
    position: relative;
    padding: 0;
    color: ${Palette.state.info};
    ${Text.Mobile.medium.medium};
    border: none;
    justify-content: center;

    ${Screen.desktop} {
        justify-content: center;
        height: 50px;
        ${Text.Desktop.normal.medium};
    }

    &:hover,
    &:active {
        border: none;

        ${ShareButtonWrapper} {
            background-color: ${Palette.state.info};

            > svg {
                color: ${Palette.white.normal};
            }
        }
    }
`;

export const Divider = styled.div`
    width: 100%;
    border-bottom: ${borders.small} ${cssVars.bg_gray4_dark2};
    margin: 20px 0 16px;
    ${Screen.desktop} {
        margin: 24px 0;
    }
`;

export const IconButtonHearth = styled(HeartIcon)`
    width: 16px;
    height: 16px;
    color: ${Palette.state.error};
`;

export const IconButtonShare = styled(ShareButtonIcon)`
    height: 17px;
    width: 17px;
    color: ${Palette.state.info};
`;

export const RedHeartWrapper = styled.div`
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${cssVars.bg_white_blue};
    border: ${borders.small} ${cssVars.bg_ash_darker};
    border-radius: ${radius.large};
    box-shadow: ${cssVars.shadow_card_default};
    margin-right: 8px;
    > svg {
        height: 17px;
        width: 17px;
    }
`;

export const Dollar = styled.span`
    ${Text.Mobile.large.semibold};

    ${Screen.ipad} {
        ${Text.Ipad.heading4};
    }
    ${Screen.desktop} {
        ${Text.Desktop.heading3};
    }
`;

export const WishListBtnFilled = styled(HeartIconFilled)`
    width: 18px;
    height: 18px;
    stroke: ${Palette.state.error};
    *:first-child {
        fill: ${Palette.state.error};
    }
`;

export const StrikeOut = styled.span`
    text-decoration: line-through;
`;

export const Price = styled.span`
    ${Text.Full.heading2};
    color: ${cssVars.fg_gray1_white};
`;

export const ShareDropdown = styled.div<{ isOpen: boolean }>`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: absolute;
    top: 50px;
    right: 25px;
    box-shadow: ${({ isOpen }) => (isOpen ? `${cssVars.shadow_card_default}` : 'none')};
    z-index: 10;
    border: ${borders.small} ${Palette.state.info};
    background-color: ${cssVars.bg_white_dark3};
    border-radius: ${radius.normal};
    padding: 10px 10px;
    width: 136px;

    &:before {
        content: '';
        height: 14px;
        width: 14px;
        background: ${cssVars.bg_white_dark3};
        transform: rotate(45deg);
        position: absolute;
        top: -8px;
        left: 30px;
        border: ${borders.small} ${Palette.state.info};
        border-bottom-width: 0;
        border-right-width: 0;
        border-top-left-radius: 3px;
    }
`;

export const ShareDropdownItem = styled.div`
    display: flex;
    padding: 4px 10px;
    border-radius: ${radius.normal};
    color: ${cssVars.fg_gray2_gray3};
    align-items: center;

    > svg {
        width: 22px;
        height: 22px;
        margin-right: 8px;
    }

    &:hover,
    &:active {
        background-color: ${`${Palette.state.info}1a`};
    }
`;
