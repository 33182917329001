import { IAccordionTab } from '@/common/ui/containers/Accordion/Accordion';
import Paragraph from '@/shared/Paragraph/ui/Paragraph';

export const getContent = (
    title: string,
    data: object,
    key: string,
    id: string
): IAccordionTab | undefined => {
    const content = data[key as keyof typeof data];

    if (content) {
        return {
            title,
            id,
            content: <Paragraph id={`activity_${key}`} as="div" innerHtml={content} />,
        };
    }

    return undefined;
};
