import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import styled, { css } from 'styled-components';
import { TParapgrah } from './Paragraph.types';
import Text from '@/styles/Themes/Text';
import { borders } from '@/styles/Themes/Metrics';

export const Paragraph = styled.p<TParapgrah>`
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.large.regular};
    margin: 1em auto;
    line-height: 18px;
    width: 100%;

    strong {
        color: ${Palette.green.normal};
    }

    a {
        color: ${Palette.state.info};

        &:hover {
            text-decoration: underline;
        }
    }

    ${(props) =>
        props.marginBottom &&
        css`
            margin-bottom: ${props.marginBottom};
            &:last-child {
                margin-bottom: ${props.marginBottom};
            }
        `};

    ${(props) =>
        props.marginBottom &&
        css`
            margin-bottom: ${props.marginBottom};
            &:last-child {
                margin-bottom: ${props.marginBottom};
            }
        `};

    ${(props) =>
        props.useInitialStyle &&
        css`
            color: initial;
            font-size: 16px;
        `};
    a {
        word-wrap: break-word;
    }
    h1,
    h2,
    h3,
    h4 {
        clear: both;
        font-weight: 300;
        font-size: 2rem;
        line-height: 1;
    }
    h1 {
        font-size: 2.5rem;
        line-height: 1;
    }
    h4 {
        font-size: 1.5rem;
    }
    p img {
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
        margin: 0 auto 20px;
        @media (min-width: 650px) {
            max-width: 650px;
            margin-bottom: 30px;
        }
    }
    iframe {
        display: block;
        width: 100%;
        height: 66vw;
        margin: 0 auto 20px;
        @media (min-width: 650px) {
            width: 638px;
            height: 440px;
            margin-bottom: 30px;
        }
    }

    &.table-layout,
    &.highlights {
        table {
            border: ${borders.small} #666666;
            font-size: 14px;
            min-width: 320px;
            max-width: 760px;
            width: 100%;
            margin: 0 auto;
            border-spacing: 0px;
        }
        thead {
            background-color: rgb(43, 59, 70);
            color: #ffffff;
        }
        th {
            padding: 5px;
        }
        td {
            padding: 3px;
        }
    }
`;
