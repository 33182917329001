import { ReactElement } from 'react';
import * as S from './ActivityDescription.styles';

type ActivityDescriptionProps = {
    icon: ReactElement;
    title: string;
    description: string;
};

export const ActivityDescription = ({ icon, title, description }: ActivityDescriptionProps) => {
    return (
        <S.ActivityDescriptionContainer>
            <S.IconContainer>{icon}</S.IconContainer>
            <S.TextContainer>
                <S.Title>{title}</S.Title>
                <S.Description>{description}</S.Description>
            </S.TextContainer>
        </S.ActivityDescriptionContainer>
    );
};
