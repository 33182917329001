import { memo } from 'react';
import * as S from './MainErrorMessage.styles';

type ErrorMessageProps = {
    message: string;
};

export const MainErrorMessageContainer = ({ message }: ErrorMessageProps) => {
    return (
        <S.ErrorText>
            <S.ErrorIcon />
            {message}
        </S.ErrorText>
    );
};

export const MainErrorMessage = memo(MainErrorMessageContainer);
