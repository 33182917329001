import { PhoneIcon } from '@/shared/images/icons/PhoneIcon';
import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import { Button } from '@/common/ui';
import { VectorGreen } from '@/shared/images/icons/VectorGreen';
import Screen from '@/styles/Themes/Screen';

export const ActivitySubtitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0 13px;

    ${Screen.ipad} {
        padding: 0 0 16px;
    }

    ${Screen.desktop} {
        padding: 0 0 30px;
    }
`;

export const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    ${Screen.desktop} {
        flex-direction: row;
        align-items: end;
    }
`;

export const RightSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 140px;
    height: 32px;

    ${Screen.ipad} {
        height: 40px;
        width: 220px;
    }
    ${Screen.desktop} {
        height: 40px;
        width: 180px;
    }

    > button {
        ${Text.Full.medium.medium};
    }
`;

export const Location = styled.div`
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 5px;
    ${Text.Full.large.regular};
    color: ${cssVars.fg_gray2_gray3};
`;

export const Ratings = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-self: start;
    gap: 5px;
    padding: 2px 0 0;

    ${Screen.desktop} {
        margin-left: 10px;
    }
`;

export const ReviewsCountLink = styled.a`
    ${Text.Mobile.medium.medium}
    color: ${Palette.state.info};
    text-decoration: underline;
    cursor: pointer;

    ${Screen.ipad} {
        ${Text.Ipad.medium.regular};
    }
    ${Screen.desktop} {
        ${Text.Desktop.large.regular};
    }
`;

export const TalkToUsIcon = styled(PhoneIcon)``;

export const LocationIcon = styled(VectorGreen)`
    display: flex;
    height: auto;
    ${Screen.desktop} {
        height: 15px;
        width: 13px;
    }
`;

export const TalkToUsButton = styled(Button)`
    padding: 0;
    min-width: 70px;

    > svg {
        ${Screen.desktop} {
            height: 16px;
            width: 16px;
        }
    }
`;

export const TalkToUsLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    padding: 6px 15px;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #0dd1c5;
    border: 1px solid #0dd1c5;
    border-radius: 50px;
    &:hover {
        border-color: #0db8ae;
    }

    ${Screen.ipad} {
        font-size: 14px;
    }

    > svg {
        ${Screen.desktop} {
            height: 16px;
            width: 16px;
        }
    }
`;

export const BeFirst = styled.span`
    color: ${Palette.sand.darkSand};
    text-decoration: underline;
    ${Text.Full.medium.regular};

    ${Screen.desktop} {
        margin: 0 30px;
    }
`;
