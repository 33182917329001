import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import { Tooltip } from '@/shared/images/icons/Tooltip';
import Palette from '@/styles/Themes/Palette';

export const Line = styled.div`
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.medium.regular}
    display: flex;
    align-items: center;
    min-height: 32px;
    justify-content: space-between;
`;

export const Icon = styled(Tooltip)`
    width: 12px;
    height: 12px;
    stroke: ${cssVars.fg_gray1_white};
    cursor: pointer;
`;

export const Price = styled.span`
    ${Text.Full.medium.semibold}
`;

export const StrikeOut = styled.span`
    ${Text.Full.medium.semibold}
    color: ${Palette.state.error};
    text-decoration: line-through;
`;

export const Title = styled.h2`
    color: ${cssVars.fg_gray1_white};
    margin: 0;
    ${Text.Full.heading2}
`;
