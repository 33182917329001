export const PhoneIcon = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.01194 12C7.06668 12 4.88563 11.122 3.2351 9.42439C1.93826 8.13659 1.05405 6.43902 0.759317 4.8C0.46458 2.98537 0.877212 1.40488 1.93826 0.35122C2.05616 0.234146 2.233 0.117073 2.40984 0.0585366C2.52774 0 2.70458 0 2.88142 0C3.05826 0 3.2351 0.117073 3.41195 0.17561C3.58879 0.234146 3.70668 0.409756 3.76563 0.585366L5.35721 2.69268C5.593 3.0439 5.65194 3.39512 5.593 3.74634C5.593 4.09756 5.41615 4.44878 5.12142 4.74146L4.94458 4.91707C4.70879 5.15122 4.53194 5.50244 4.53194 5.9122C4.53194 6.26341 4.64984 6.61463 4.94458 6.90732L5.82878 7.78537C6.35931 8.31219 7.24352 8.31219 7.77405 7.78537L7.95089 7.60976C8.18668 7.37561 8.54036 7.2 8.89404 7.14146C9.24773 7.08293 9.60141 7.2 9.9551 7.37561L12.1361 9.01463C12.254 9.07317 12.3719 9.19024 12.4898 9.36585C12.6077 9.54146 12.6667 9.71707 12.6667 9.89268C12.6667 10.0683 12.6667 10.2439 12.6077 10.4195C12.5488 10.5951 12.4309 10.7707 12.313 10.8878C11.4877 11.5902 10.3088 12 9.01194 12ZM2.76353 0.878049C2.70458 0.878049 2.70458 0.878049 2.64563 0.878049C2.58668 0.878049 2.58668 0.936585 2.52774 0.936585C1.64353 1.7561 1.34879 3.10244 1.58458 4.62439C1.82037 6.14634 2.64563 7.66829 3.82458 8.78049C6.30036 11.239 9.9551 11.8244 11.7235 10.0683C11.7825 10.0098 11.7825 10.0098 11.7825 9.95122C11.7825 9.89268 11.7825 9.83415 11.7825 9.83415C11.7825 9.77561 11.7825 9.71707 11.7235 9.71707C11.7235 9.65854 11.6646 9.65854 11.6056 9.6L9.48352 8.01951C9.36562 7.96098 9.18878 7.90244 9.01194 7.96098C8.8351 7.96098 8.7172 8.07805 8.54036 8.19512L8.36352 8.37073C7.47931 9.19024 6.00563 9.19024 5.18036 8.37073L4.29615 7.49268C3.94247 7.02439 3.70668 6.43902 3.70668 5.85366C3.70668 5.26829 3.94247 4.68293 4.3551 4.27317L4.53194 4.09756C4.64984 3.98049 4.70879 3.80488 4.76773 3.62927C4.76773 3.45366 4.76773 3.27805 4.64984 3.16098L3.05826 1.05366C2.99931 0.995122 2.94037 0.936585 2.94037 0.936585C2.88142 0.878049 2.82247 0.878049 2.76353 0.878049Z"
            fill="url(#paint0_linear_8793_49089)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_8793_49089"
                x1="0.666672"
                y1="6"
                x2="12.6667"
                y2="6"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#03D9B1" />
                <stop offset="1" stopColor="#1CBBD9" />
            </linearGradient>
        </defs>
    </svg>
);
