import { memo } from 'react';
import * as S from '../ActivityPage.styles';
import { FeaturedActivities } from '@/shared/FeaturedActivities/ui/FeaturedActivities';
import { useActivityContext } from '@/common/app/contexts/ActivityContext';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';

const ActivityFeaturedActivitiesContainer = ({
    title,
    isGiftCard,
    currentActivity,
}: {
    title: string;
    isGiftCard: boolean;
    currentActivity: IActivity;
}) => {
    const { recommends } = useActivityContext();

    if (!recommends.length) {
        return null;
    }

    const item_list_id = typeof window !== 'undefined' ? window.location.pathname : '';

    return (
        <S.WrapperWithMargin isGiftCard={isGiftCard}>
            <FeaturedActivities
                activities={recommends.filter((i) => i.id !== currentActivity.id)}
                title={title}
                item_list_id={item_list_id}
                item_list_name={title}
                skipSkeleton
            />
        </S.WrapperWithMargin>
    );
};

export const ActivityFeaturedActivities = memo(ActivityFeaturedActivitiesContainer);
