import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';

export const OptionLine = styled.div`
    display: flex;
    align-items: baseline;
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.large.medium};
`;

export const Price = styled.span`
    color: ${Palette.green.normal};
    white-space: nowrap;
`;

export const LowPrice = styled.span`
    display: none;

    ${Screen.ipad} {
        display: inline;
    }
`;

export const SoldOut = styled.span`
    color: ${Palette.state.error};
    cursor: not-allowed;
`;
