import { getNormalisedPrice } from '@/common/app/utils/getNormalisedPrice';
import { format, parseISO } from 'date-fns';
import { memo, ReactElement } from 'react';
import * as S from './DayItem.styles';

type DayItemProps = {
    isActive?: boolean;
    isSoldout?: boolean;
    minPrice: number;
    date: string;
    changeDay: () => void;
};

export const DayItem = ({
    date,
    isActive,
    isSoldout,
    minPrice,
    changeDay,
}: DayItemProps): ReactElement => {
    const parsedDate = parseISO(date);
    const weekDay = format(parsedDate, 'EEE');
    const monthDay = format(parsedDate, 'd');
    const statusText = (isSoldout && 'Sold out') || '';
    const minPriceWithComma = getNormalisedPrice(minPrice);

    return (
        <S.Container onClick={changeDay}>
            {statusText ? (
                <S.BookNow>{statusText}</S.BookNow>
            ) : (
                <S.Price>
                    <S.PriceLabel>From </S.PriceLabel>
                    <S.PriceValue>{minPriceWithComma}</S.PriceValue>
                </S.Price>
            )}
            <S.DateBox isActive={isActive} isSoldout={isSoldout}>
                {weekDay}
                <br />
                {monthDay}
            </S.DateBox>
        </S.Container>
    );
};

export default memo(DayItem);
