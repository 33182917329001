import { ReactElement } from 'react';
import * as S from './TicketLabel.styles';
import { Tooltip } from '@/common/ui/containers/Tooltip/Tooltip';
import { getNormalisedPrice } from '@/common/app/utils/getNormalisedPrice';

type TicketLabelProps = {
    name: string;
    description: string;
    price: number;
    strikeOut?: number;
};

export const TicketLabel = ({
    name,
    price,
    strikeOut,
    description,
}: TicketLabelProps): ReactElement => {
    const priceWithComma = getNormalisedPrice(price);
    const priceStrikeOutWithComma = getNormalisedPrice(strikeOut);

    return (
        <S.Line>
            <div>
                {name.toUpperCase()}
                {!!strikeOut && (
                    <>
                        &nbsp;<S.StrikeOut>{priceStrikeOutWithComma}</S.StrikeOut>
                    </>
                )}
                <S.Price>&nbsp;{priceWithComma}</S.Price>
            </div>
            {description && (
                <Tooltip description={description} inCart={false}>
                    <S.Icon />
                </Tooltip>
            )}
        </S.Line>
    );
};
