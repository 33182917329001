import { ReactElement } from 'react';
import { Wrapper, LdsEllipsis } from './LoadMore.styles';

export const LoadMore = ({
    isLoading,
    noMargin,
    white,
}: {
    isLoading: boolean;
    noMargin?: boolean;
    white?: boolean;
}): ReactElement => {
    return (
        <>
            {isLoading && (
                <Wrapper noMargin={noMargin}>
                    <LdsEllipsis isWhite={white}>
                        <div />
                        <div />
                        <div />
                        <div />
                    </LdsEllipsis>
                </Wrapper>
            )}
        </>
    );
};
