import { ReactElement, memo } from 'react';
import * as S from './Calendar.styles';
import CalendarHeader from './CalendarHeader/CalendarHeader';
import CalendarBody from './CalendarBody/CalendarBody';
import { Container } from '@/common/ui/containers/Container/Container';
import { IBreadcrumbsItem } from '@/shared/Breadcrumbs/domain/Breadcrumbs.domain';
import { CalendarSkeleton } from './CalendarSkeleton/CalendarSkeleton';
import { useActivityContext } from '@/common/app/contexts/ActivityContext';

type CalendarProps = {
    title: string;
    isGiftCard: boolean;
    lastBreadcrumb: IBreadcrumbsItem[];
    reservationIndex: number;
    handleClickOnTab: (value: number) => void;
};

export const Calendar = ({
    title,
    isGiftCard,
    handleClickOnTab,
    lastBreadcrumb,
    reservationIndex,
}: CalendarProps): ReactElement => {
    const { availability } = useActivityContext();
    const hasAvail = !!availability.days_info;

    return (
        <Container data-test-id="availability-calendar">
            <S.Title isGiftCard={isGiftCard}>{title}</S.Title>
            {!isGiftCard && <S.AvaiCalendar>Availability Calendar</S.AvaiCalendar>}
            {hasAvail ? (
                <S.CaledarWrapper>
                    <CalendarHeader />
                    <CalendarBody
                        isGiftCard={isGiftCard}
                        handleClickOnTab={handleClickOnTab}
                        lastBreadcrumb={lastBreadcrumb}
                        reservationIndex={reservationIndex}
                    />
                </S.CaledarWrapper>
            ) : (
                <CalendarSkeleton />
            )}
        </Container>
    );
};

export default memo(Calendar);
