import styled, { DefaultTheme } from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { Calendar } from '@/shared/images/icons/Calendar';
import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';
import Palette from '@/styles/Themes/Palette';

export const Container = styled.div`
    position: relative;
    background-color: ${cssVars.bg_ash_blueDark};
    padding: 30px 16px;

    ${Screen.ipad} {
        padding: 44px 32px 41px;
    }
    ${Screen.desktop} {
        padding: 42px 47px 40px;
    }
`;

export const CalendarBtn = styled.div`
    appearance: none;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    &:hover {
        text-decoration: underline;
    }
`;

export const CalendarIcon = styled(Calendar)`
    margin-right: 10px;
`;

export const CalendarLine = styled.div`
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.large.semibold}
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px;
    cursor: pointer;

    ${Screen.ipad} {
        width: 70%;
        margin: 0 auto 35px;
    }
    ${Screen.desktop} {
        height: 30px;
        width: 60%;
    }
`;

export const DaysLine = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;

    ${Screen.ipad} {
        flex-wrap: nowrap;
    }
`;

export const FirstLine = styled.div`
    display: grid;
    grid-template: 1fr / repeat(3, 1fr);
    grid-gap: 10px;
    width: 72%;
    height: auto;
    margin: 0 auto 15px;

    ${Screen.ipad} {
        margin: 0 10px 0 0;
        grid-template: 1fr / repeat(3, 1fr);
        align-items: end;
        width: 74%;
    }

    ${Screen.desktop} {
        margin: 0 20px 0 0;
        grid-gap: 20px;
    }
`;

export const SecondLine = styled.div`
    display: grid;
    grid-template: 1fr / repeat(4, 1fr);
    grid-gap: 10px;
    width: 100%;
    align-items: end;

    ${Screen.desktop} {
        grid-gap: 20px;
    }
`;

interface IDisable extends DefaultTheme {
    disabled?: boolean;
}

export const PrevArrow = styled.button<IDisable>`
    display: flex;
    align-items: center;
    width: 95px;
    height: 36px;
    border-radius: 36px;
    box-shadow: ${cssVars.shadow_card_default};
    background-color: ${cssVars.bg_white_blue};
    border: none;
    ${Text.Mobile.normal.medium};
    color: ${({ disabled }) => (disabled ? cssVars.fg_gray2_gray3 : cssVars.fg_gray1_white)};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    ${Screen.ipad} {
        justify-content: center;
        ${Text.Full.medium.medium};
        width: 145px;
        height: 30px;
        margin: 0 30px 0 0;
    }

    ${Screen.desktop} {
        width: 145px;
        height: 30px;
    }
`;

export const NextArrow = styled.button`
    display: flex;
    justify-content: end;
    align-items: center;
    width: 95px;
    height: 36px;
    border-radius: 36px;
    box-shadow: ${cssVars.shadow_card_default};
    background-color: ${cssVars.bg_white_blue};
    border: none;
    ${Text.Mobile.normal.medium};
    color: ${cssVars.fg_gray1_white};
    cursor: pointer;
    padding: 0 0 0 5px;

    ${Screen.ipad} {
        justify-content: center;
        ${Text.Full.medium.medium};
        width: 145px;
        height: 30px;
        margin: 0 0 0 30px;
    }

    ${Screen.desktop} {
        width: 145px;
        height: 30px;
        padding: 0;
    }
`;

export const LeftArrow = styled(DropdownArrow)<IDisable>`
    transform: rotate(90deg);
    stroke: ${({ disabled }) => (disabled ? Palette.gray.gray3 : cssVars.fg_gray1_white)};
`;

export const RightArrow = styled(DropdownArrow)`
    transform: rotate(-90deg);
    stroke: ${cssVars.fg_gray1_white};

    ${Screen.desktop} {
        margin: 0 0 0 10px;
    }
`;
