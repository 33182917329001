import styled, { css } from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import { radius } from '@/styles/Themes/Metrics';

export const Container = styled.div`
    text-align: center;
    cursor: pointer;
    align-self: flex-end;
`;

export const BookNow = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Mobile.normal.semibold}

    ${Screen.ipad} {
        ${Text.Desktop.medium.semibold}
    }
`;

export const PriceLabel = styled.div`
    ${Text.Mobile.normal.medium};
    margin-right: 3px;
    ${Screen.ipad} {
        ${Text.Ipad.normal.medium};
    }
    ${Screen.desktop} {
        ${Text.Desktop.medium.medium};
    }
`;

export const PriceValue = styled.div`
    ${Text.Mobile.normal.semibold};
    ${Screen.ipad} {
        ${Text.Ipad.normal.semibold};
    }

    &:first-letter {
        ${Text.Mobile.small.semibold};
    }

    ${Screen.desktop} {
        ${Text.Desktop.heading3};

        &:first-letter {
            ${Text.Desktop.large.semibold};
        }
    }
`;

export const Price = styled.div`
    color: ${cssVars.fg_gray1_white};
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;

    ${Screen.ipad} {
        flex-wrap: wrap;
    }
`;

export const DateBox = styled.div<{ isActive?: boolean; isSoldout?: boolean }>`
    ${({ isActive, isSoldout }) => {
        if (isSoldout) {
            return css`
                background-color: ${Palette.state.error};
                color: ${Palette.white.normal};
                ${isActive && 'transform: scale(1.1);'}
            `;
        }
        if (isActive) {
            return css`
                background-color: ${Palette.green.normal};
                color: ${Palette.white.normal};
                transform: scale(1.1);
            `;
        }
        return css`
            background-color: ${cssVars.bg_greenWhite_greenBlack};
            color: ${cssVars.fg_gray2_white};
        `;
    }}

    ${Text.Full.medium.semibold}
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${radius.small};

    width: 100%;

    height: 72px;
    margin-top: 6px;

    ${Screen.ipad} {
        height: 90px;
        margin-top: 8px;
    }

    ${Screen.ipad} {
        height: 100px;
        margin-top: 14px;
    }
`;

export const Dollar = styled.span`
    ${Text.Mobile.small.semibold};

    ${Screen.desktop} {
        ${Text.Desktop.large.semibold};
    }
`;
