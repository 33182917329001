import styled, { css } from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';
import { FlexPresets } from '@/styles/Themes/Presets';

export const Container = styled.div<{ isActive?: boolean }>`
    background-color: ${cssVars.bg_ash_dark2};
    position: relative;
    margin-top: 14px;
    padding: 20px 18px;
    border-radius: ${radius.small};
    ${({ isActive }) => css`
        border: 1px solid ${isActive ? Palette.green.normal : cssVars.fg_gray3_dark1};
        ${!!isActive && 'cursor: pointer;'}
    `}

    ${Screen.desktop} {
        margin-top: 18px;
        padding: 40px 30px;
    }
`;

export const OpenContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Option = styled.div<{ isActive: boolean }>`
    color: ${Palette.white.normal};
    ${Text.Full.medium.semibold}
    padding: 4px 14px;
    margin-bottom: 16px;
    background: ${({ isActive }) =>
        isActive ? `${Palette.gradient.light}` : `${Palette.gradient.gray}`};
    width: max-content;
    border-radius: ${radius.large};

    ${Screen.ipad} {
        margin-bottom: 22px;
        padding: 4px 38px;
    }
`;

export const RateName = styled.span<{ isActive: boolean }>`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.large.semibold}
    display: flex;
    justify-content: space-between;
`;

export const ShowMore = styled.div`
    background-color: ${cssVars.bg_ash_dark2};
    border-radius: ${radius.large};
    width: 100%;
    height: 50px;
    margin: 16px 0 0;
    text-align: center;
    padding: 15px 0;
    ${Text.Full.large.medium}
    color: ${cssVars.fg_gray2_gray3};
    cursor: pointer;
    border: 1px solid ${cssVars.fg_gray3_dark1};

    ${Screen.ipad} {
        width: 250px;
        margin: 30px auto 0;
    }
`;

export const ButtonWrapper = styled.div<{ isActive: boolean }>`
    display: ${({ isActive }) => (isActive ? `block` : `none`)};
    width: 75%;
    margin: 20px auto 0;

    ${Screen.desktop} {
        display: none;
    }
`;

export const Arrow = styled(DropdownArrow)<{ isActive: boolean }>`
    display: ${({ isActive }) => (isActive ? 'none' : 'flex')};
`;

export const SoldoutLabel = styled.div`
    ${FlexPresets.flexRowCenter};
    width: 106px;
    height: 24px;
    border-radius: ${radius.large};
    color: ${Palette.white.normal};
    ${Text.Full.medium.semibold}
    background: ${cssVars.button_disabled};
    text-align: center;
    padding-top: 1px;

    ${Screen.ipad} {
        width: 158px;
    }
    ${Screen.desktop} {
        width: 140px;
    }
`;
