export const Tooltip = (props: { className?: string }) => {
    return (
        <svg
            className={`Tooltip ${props.className}`}
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="12.5"
                cy="12.5"
                r="11.75"
                stroke="var(--fg-gray2-gray3)"
                strokeWidth="1.5"
            />
            <path
                d="M12.5714 21C11.6786 21 11.1429 20.4884 11.1429 19.7209V12.2001H11.0504C10.1856 12.2001 9.18385 12.1269 9.01557 11.2787V11.2787C8.76191 10 11.6786 10 12.5714 10C13.4643 10 14 10.5116 14 11.2791V19.7209C13.8214 20.4884 13.2857 21 12.5714 21Z"
                fill="var(--fg-gray2-gray3)"
            />
            <path
                d="M12.5 8C11.5625 8 11 7.4 11 6.6C11 5.6 11.5625 5 12.5 5C13.4375 5 14 5.6 14 6.6C14 7.6 13.25 8 12.5 8Z"
                fill="var(--fg-gray2-gray3)"
            />
        </svg>
    );
};
