import { Fragment, ReactElement, ReactNode, useId } from 'react';
import * as S from './CalendarSkeleton.styles';
import { PaymentBlock } from '../CalendarBody/PaymentBlock/PaymentBlock';

const Repeat = (Item: ReactNode, count: number) => {
    return (
        <>
            {Array(count)
                .fill(0)
                .map(() => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const id = useId();
                    return <Fragment key={id}>{Item}</Fragment>;
                })}
        </>
    );
};

const Day = () => {
    return (
        <div>
            <S.Price />
            <S.Box />
        </div>
    );
};

export const CalendarSkeleton = (): ReactElement => {
    return (
        <S.Wrapper>
            <S.Header>
                <S.CalendarLine>{Repeat(<S.NextBtn />, 3)}</S.CalendarLine>
                <S.DaysLine>
                    <S.FirstLine>{Repeat(<Day />, 3)}</S.FirstLine>
                    <S.SecondLine>{Repeat(<Day />, 4)}</S.SecondLine>
                </S.DaysLine>
            </S.Header>
            <S.Body>
                <S.DateLine>
                    <S.Text />
                </S.DateLine>
                <S.Text mWidth={150} dWidth={180} margin />
                <S.Selector />
                <S.Text margin />
                <S.Selector />
                <S.Text mWidth={150} dWidth={180} margin />
                <S.Radio />
                <S.Radio />
                <S.Text margin />
                <S.Textarea />
                <S.AddButton />
                <PaymentBlock />
            </S.Body>
        </S.Wrapper>
    );
};
