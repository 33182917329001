import { ReactElement, memo, ReactNode, useState, useCallback, useRef } from 'react';
import * as S from './TooltipOnClick.styles';
import { useOnClickOutside } from '@/common/service/hooks';

type TooltipProps = {
    children: ReactNode;
    description: ReactNode;
};

export const TooltipContainer = ({ children, description }: TooltipProps): ReactElement => {
    const [show, setShow] = useState(false);
    const refContainer = useRef(null);

    const handleClickOutside = useCallback(() => {
        setShow(false);
    }, []);

    useOnClickOutside(refContainer, handleClickOutside);

    return (
        <S.Container ref={refContainer} onClick={() => setShow(true)}>
            {show && description && <S.Title>{description}</S.Title>}
            {children}
        </S.Container>
    );
};

export const TooltipOnClick = memo(TooltipContainer);
