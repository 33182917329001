import { IDateRange } from '@/common/domain/Date.domain';
import { useOnClickOutside } from '@/common/service/hooks';
import { useMedia } from '@/common/service/hooks/useMedia';
import { IMobileSearchState, ISearchState } from '@/shared/SearchBar/types/index.types';
import { addDays, format, parseISO } from 'date-fns';
import {
    useState,
    useRef,
    ReactNode,
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
} from 'react';
import { Drawer } from '../../drawer';
import { Loader } from '../../loaders';
import { MobileAbsoluteContainer } from '../InputSearch/InputSearch.styles';

import * as S from './AvailabilityDatePicker.styles';

type DatePickerProps = {
    label?: string;
    dateStart?: Date;
    dateEnd?: Date;
    children?: ReactNode;
    pastDisabled?: boolean;
    mobileState?: IMobileSearchState;
    isNotSearch?: boolean;
    onChange?: (dateRange: IDateRange) => void;
    onHandleSearch?: () => void;
    setMobileState?: Dispatch<SetStateAction<IMobileSearchState>>;
    setCalendarDate: Dispatch<SetStateAction<string>>;
    notAvailableDays: number[];
    isLoad: boolean;
};

export const AvailabilityDatePicker = (props: DatePickerProps) => {
    const today = new Date();
    const lastDay = addDays(today, 6);
    const ref = useRef<HTMLDivElement>(null);
    const refResult = useRef<HTMLDivElement>(null);
    const mobileRef = useRef<HTMLDivElement>(null);
    const isDesktop = useMedia('(min-width: 992px)');
    const { setCalendarDate, onChange, notAvailableDays, isLoad, label, pastDisabled } = props;

    const dateStart =
        typeof props.dateStart === 'string' ? parseISO(props.dateStart) : props.dateStart || today;
    const dateEnd =
        typeof props.dateEnd === 'string' ? parseISO(props.dateEnd) : props.dateEnd || lastDay;

    const [{ open, closeWithDelay, isProgress }, setState] = useState<ISearchState>({
        open: false,
        closeWithDelay: false,
        isProgress: false,
    });

    const onMonthChange = (date: string) => {
        setCalendarDate(date);
    };

    const { mobileState, children, isNotSearch, setMobileState } = props;

    useOnClickOutside(ref, () => hideResults());

    const handleClose = useCallback(() => {
        if (!open) return;

        setState({
            open: false,
            closeWithDelay: false,
            isProgress: false,
        });

        setMobileState && setMobileState({});
    }, [open, setMobileState]);

    const closeWithAnimation = useCallback(() => {
        setState((prev) => ({
            ...prev,
            isProgress: true,
        }));
        if (mobileState) {
            mobileRef.current && mobileRef.current.classList.add('hide');
        } else {
            refResult.current && refResult.current.classList.add('hide');
        }
        setTimeout(() => {
            handleClose();
        }, 100);
    }, [handleClose, mobileState]);

    useEffect(() => {
        if (mobileState?.goBack && mobileState?.label === 'Trip Dates') {
            closeWithAnimation();
        }
    }, [mobileState, closeWithAnimation]);

    const handleChange = (dateStart: Date, dateEnd: Date) => {
        const newDateRange: IDateRange = {
            dateStart: dateStart,
            dateEnd: dateEnd,
        };

        onChange?.(newDateRange);
        isDesktop || mobileState
            ? closeWithAnimation()
            : setState((prev) => ({
                  ...prev,
                  closeWithDelay: true,
              }));
    };

    const displayResults = () => {
        if (isProgress || open) return;

        setState((prev) => ({
            ...prev,
            open: true,
        }));
        if (setMobileState) {
            setMobileState({
                height: 375,
                label: 'Trip Dates',
            });
        }
    };

    const hideResults = () => {
        if (!open) return;
        if (isDesktop && open) {
            closeWithAnimation();
        }
    };

    const isNotAvailable = (d: number) => {
        return notAvailableDays.includes(d);
    };

    return (
        <>
            <S.Wrapper
                onClick={displayResults}
                ref={ref}
                isNotSearch={isNotSearch}
                data-test-id="Date_picker"
            >
                {children ? (
                    <S.ChildrenContainer onClick={handleClose}>{children}</S.ChildrenContainer>
                ) : (
                    <>
                        <S.Container>
                            {label && <S.Label>{label}</S.Label>}
                            <S.Placeholder>
                                {`${format(dateStart, 'MMM d')} ~ ${format(dateEnd, 'MMM d')}`}
                            </S.Placeholder>
                        </S.Container>
                        <S.Icons>
                            <S.ButtonArrow>
                                <S.IconCalendar />
                            </S.ButtonArrow>
                        </S.Icons>
                    </>
                )}
                {open && !mobileState && (
                    <>
                        {isDesktop ? (
                            <>
                                <S.ResultsContainer ref={refResult} isNotSearch={isNotSearch}>
                                    {isLoad && (
                                        <S.Gradient>
                                            <Loader />
                                        </S.Gradient>
                                    )}
                                    <S.InputDate
                                        dateStart={dateStart}
                                        dateEnd={dateEnd}
                                        onChange={handleChange}
                                        pastDisabled={pastDisabled}
                                        onMonthChange={onMonthChange}
                                        isNotAvailable={isNotAvailable}
                                        isLoad={isLoad}
                                        oneWeekOnly
                                    />
                                </S.ResultsContainer>
                            </>
                        ) : (
                            <Drawer
                                open={open}
                                setOpen={handleClose}
                                title={'Trip dates'}
                                closeWithDelay={closeWithDelay}
                            >
                                <S.ResultsContainer>
                                    {isLoad && (
                                        <S.Gradient>
                                            <Loader />
                                        </S.Gradient>
                                    )}
                                    <S.InputDate
                                        dateStart={dateStart}
                                        dateEnd={dateEnd}
                                        onChange={handleChange}
                                        pastDisabled={pastDisabled}
                                        onMonthChange={onMonthChange}
                                        isNotAvailable={isNotAvailable}
                                        isLoad={isLoad}
                                        oneWeekOnly
                                    />
                                </S.ResultsContainer>
                            </Drawer>
                        )}
                    </>
                )}
            </S.Wrapper>
            {mobileState && open && (
                <MobileAbsoluteContainer isVisible={open} ref={mobileRef}>
                    <S.ResultsContainer>
                        {isLoad && (
                            <S.Gradient>
                                <Loader />
                            </S.Gradient>
                        )}
                        <S.InputDate
                            dateStart={dateStart}
                            dateEnd={dateEnd}
                            onChange={handleChange}
                            pastDisabled={pastDisabled}
                            onMonthChange={onMonthChange}
                            isNotAvailable={isNotAvailable}
                            isLoad={isLoad}
                            oneWeekOnly
                        />
                    </S.ResultsContainer>
                </MobileAbsoluteContainer>
            )}
        </>
    );
};
