import { ReactElement, memo, useMemo } from 'react';
import * as S from './RadioOptions.styles';
import RadioButton from '@/common/ui/inputs/RadioButton/RadioButton';
import { Schedule } from '@/common/service/api/Availability/Availability.domain';
import OptionName from '../OptionName/OptionName';
import { useActivityContext } from '@/common/app/contexts/ActivityContext';
import { TicketsDropdown } from '@/common/ui/inputs/TicketsDropdown/TicketsDropdown';

type RadioOptionsProps = {
    schedules: Schedule[];
    selectedId: number;
};

export const RadioOptions = ({ schedules, selectedId }: RadioOptionsProps): ReactElement => {
    const { changeSchedule } = useActivityContext();

    const scheduleMinPrice = useMemo(
        () =>
            schedules.reduce((acc, cur) => (acc > cur.min_price || !acc ? cur.min_price : acc), 0),
        [schedules]
    );

    const allIsBest = useMemo(
        () => !schedules.find((shedule) => shedule.min_price !== scheduleMinPrice),
        [schedules, scheduleMinPrice]
    );

    const selectedOption = schedules.find((schedule) => schedule.id === selectedId) as Schedule;
    const isDropdown = schedules.length > 6;
    const dropdownClick = (index: number) => {
        const newSchedule = schedules[index];

        if (newSchedule.id && !newSchedule.sold_out) {
            changeSchedule(newSchedule.id)();
        }
    };

    return (
        <S.RadioContainer isDropdown={isDropdown}>
            {isDropdown ? (
                <TicketsDropdown
                    selected={
                        <OptionName
                            schedule={selectedOption}
                            scheduleMinPrice={scheduleMinPrice}
                            allIsBest={allIsBest}
                        />
                    }
                    list={schedules.map((option, index) => ({
                        label: (
                            <OptionName
                                key={option.id}
                                schedule={option}
                                scheduleMinPrice={scheduleMinPrice}
                                allIsBest={allIsBest}
                            />
                        ),
                        value: index,
                    }))}
                    onSelect={dropdownClick}
                />
            ) : (
                schedules.map((option) => {
                    return (
                        <RadioButton
                            checked={option.id === selectedId}
                            onClick={
                                !option.sold_out
                                    ? changeSchedule(option.id)
                                    : () => {
                                          return;
                                      }
                            }
                            key={option.id}
                            name="schedule"
                        >
                            <OptionName
                                schedule={option}
                                scheduleMinPrice={scheduleMinPrice}
                                allIsBest={allIsBest}
                            />
                        </RadioButton>
                    );
                })
            )}
        </S.RadioContainer>
    );
};

export default memo(RadioOptions);
