import { ReactElement, memo } from 'react';
import * as S from './AdditionalOptions.styles';
import { TRate } from '@/common/service/api/Availability/Availability.domain';
import { InputForm, TicketsDropdown } from '@/common/ui/inputs';
import { TSelected } from '@/common/app/contexts/ActivityContext';
import { getNormalisedPrice } from '@/common/app/utils/getNormalisedPrice';

type AdditionalOptionsProps = {
    selected: TSelected;
    selectedRate?: TRate;
    selectOption: (optionId: string, valueId: string) => void;
};

export const AdditionalOptionsContainer = ({
    selected,
    selectedRate,
    selectOption,
}: AdditionalOptionsProps): ReactElement => {
    const options = selectedRate?.additional_options;

    if (!options?.length) {
        return <></>;
    }

    return (
        <>
            <S.Subtitle data-test-id="additional-options" id="additional-options">
                Additional Options
            </S.Subtitle>
            <S.OptionGrid>
                {options?.map((option) => {
                    if (option.type === 'select') {
                        const selectedOption = option.values.find(
                            (v) => v.id === selected.additional_options[option.id]
                        );
                        return (
                            <TicketsDropdown
                                key={option.id}
                                list={option.values.map(({ name, price }, index) => ({
                                    label: (
                                        <>
                                            {name} <S.Price>{getNormalisedPrice(price)}</S.Price>
                                        </>
                                    ),
                                    value: index,
                                }))}
                                onSelect={(index) => {
                                    selectOption(option.id, option.values[index]?.id || '');
                                }}
                                title="Choose"
                                selected={
                                    selectedOption &&
                                    `${selectedOption.name} ${getNormalisedPrice(
                                        selectedOption.price
                                    )}`
                                }
                                lable={
                                    <S.OptionText>
                                        {option.required === '1' && <S.Required>*</S.Required>}
                                        {option.name}
                                    </S.OptionText>
                                }
                                closeIcon={option.required !== '1'}
                            />
                        );
                    } else {
                        return (
                            <InputForm
                                key={option.id}
                                id={option.id}
                                data-test-id="fillable-field"
                                label={option.name}
                                name="additional_options"
                                placeholder={option.name}
                                required={option.required === '1'}
                                type="text"
                                value={selected.additional_options?.[option.id]}
                                onChange={(evt) => selectOption(option.id, evt.target.value)}
                            />
                        );
                    }
                })}
            </S.OptionGrid>
        </>
    );
};

export const AdditionalOptions = memo(AdditionalOptionsContainer);
