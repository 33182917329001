import { ReactElement, memo } from 'react';
import * as S from './OptionName.styles';
import { Schedule } from '@/common/service/api/Availability/Availability.domain';
import { getNormalisedPrice } from '@/common/app/utils/getNormalisedPrice';

type OptionNameProps = {
    schedule: Schedule;
    allIsBest: boolean;
    scheduleMinPrice: number;
};

export const OptionName = ({
    schedule,
    allIsBest,
    scheduleMinPrice,
}: OptionNameProps): ReactElement => {
    const isBestValue = schedule.min_price === scheduleMinPrice;
    const minPriceWithComma = getNormalisedPrice(schedule.min_price);

    const name = schedule.name || schedule.time.replace(/(.+)(.{2})/, '$1 $2');

    return (
        <S.OptionLine>
            <span data-test-id="time">{name}&nbsp;</span>
            {schedule.tickets_avail < 50 && (
                <span data-test-id="avail">- {schedule.tickets_avail} avail&nbsp;</span>
            )}
            {schedule.min_price > 0 && (
                <span data-test-id="price">
                    -&nbsp;
                    <S.Price>
                        {minPriceWithComma}
                        {!allIsBest && isBestValue && <S.LowPrice>{` Lowest price`}</S.LowPrice>}
                    </S.Price>
                </span>
            )}
            {schedule.sold_out && <S.SoldOut>- SOLD OUT</S.SoldOut>}
        </S.OptionLine>
    );
};

export default memo(OptionName);
