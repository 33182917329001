import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import Screen from '@/styles/Themes/Screen';

export const ActivityDescriptionContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 10px;
    width: 100%;
`;

export const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: ${cssVars.svg_ash_dark2};
    width: 36px;
    height: 36px;
    > svg {
        height: 22px;
        width: 22px;
    }
    ${Screen.desktop} {
        width: 40px;
        height: 40px;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const Title = styled.div`
    ${Text.Mobile.large.medium}
    color: ${cssVars.fg_black_white};
    ${Screen.desktop} {
        ${Text.Desktop.large.semibold};
    }
`;

export const Description = styled.div`
    ${Text.Mobile.medium.regular}
    color: ${cssVars.fg_gray2_white};
    ${Screen.desktop} {
        ${Text.Desktop.medium.regular};
    }
`;
