import { ReactElement, memo, useState } from 'react';
import * as S from './CreateYourBooking.styles';
import { Schedule, Ticket } from '@/common/service/api/Availability/Availability.domain';
//import { ErrorMessage } from '../../ErrorMessage/ErrorMessage';
import { TSelected, TStatus } from '@/common/app/contexts/ActivityContext';
import { TicketsDropdown } from '@/common/ui/inputs/TicketsDropdown/TicketsDropdown';
import { TicketLabel } from '../TicketLabel/TicketLabel';

type CreateYourBookingProps = {
    selectedSchedule?: Schedule;
    status: TStatus;
    tickets: Ticket[];
    selected: TSelected;
    needTicketsGuests: boolean;
    onClickTicket: (ticketId: number) => (index: number) => void;
    stepNumbers: number[];
    isGiftCard: boolean;
};

export const CreateYourBookingContainer = ({
    selectedSchedule,
    tickets,
    selected,
    needTicketsGuests,
    onClickTicket,
    stepNumbers,
    isGiftCard,
}: CreateYourBookingProps): ReactElement => {
    const [isTouched, setIsTouched] = useState(false);

    const title = isGiftCard ? ' Choose the Amount' : ' Create your Booking';
    const ticketsErrorText = isGiftCard
        ? 'Please choose the amount'
        : 'You need at least 1 ticket(s)';
    const isSelected = tickets.some(({ id }) => !!selected.tickets[id]);
    const errorMsg = isTouched && !isSelected && ticketsErrorText;

    if (!tickets.length || !selectedSchedule) {
        return <></>;
    }

    return (
        <S.WrapperCreateYourBooking>
            <S.Subtitle data-test-id="how-many-tickets" id="tickets">
                {stepNumbers[1]}.{title}
                {Number(selectedSchedule?.min_tickets) > 1 && (
                    <S.SubtitleNote id="min-tickets">{` minimum: ${selectedSchedule?.min_tickets}`}</S.SubtitleNote>
                )}
                {/* {status.link === 'min-tickets' && <ErrorMessage message={status.message} />} */}
            </S.Subtitle>
            <S.OptionGrid>
                {tickets.map((ticket, index) => (
                    <TicketsDropdown
                        key={ticket.id}
                        list={Array(ticket.avail)
                            .fill(0)
                            .map((_, index) => ({
                                label: index + 1,
                                value: index,
                            }))
                            .filter((i) => i.value + 1 >= +ticket.min)}
                        onSelect={onClickTicket(ticket.id)}
                        title="Select"
                        selected={selected.tickets[ticket.id]}
                        lable={
                            <TicketLabel
                                name={ticket.name}
                                description={ticket.description}
                                price={ticket.price}
                                strikeOut={ticket.strike_out}
                            />
                        }
                        needTicketsGuests={needTicketsGuests}
                        ticketId={ticket.id}
                        onTouch={!isTouched ? () => setIsTouched(true) : undefined}
                        errorMsg={index === 0 && errorMsg}
                        closeErrorMsg={
                            index === 0 && errorMsg ? () => setIsTouched(false) : undefined
                        }
                        closeIcon
                    />
                ))}
            </S.OptionGrid>
        </S.WrapperCreateYourBooking>
    );
};

export const CreateYourBooking = memo(CreateYourBookingContainer);
