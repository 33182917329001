import { TAvailabilityRates, Ticket } from '@/common/service/api/Availability/Availability.domain';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';

export const createStepNumbers = (
    availability: TAvailabilityRates,
    isAvailableSchedule: boolean,
    finalTickets: Ticket[],
    activity: IActivity,
    agreement?: string
) => {
    const array = [0, 0, 0, 0];
    if (availability) {
        isAvailableSchedule ? (array[0] = 1) : (array[0] = 0);
    }
    if (finalTickets && finalTickets.length > 0) {
        array[1] = array[0] + 1;
    } else {
        array[1] = array[0];
    }
    if ((activity.policy_content && activity.policy_title) || agreement) {
        array[2] = array[1] + 1;
    } else {
        array[2] = array[1];
    }
    array[3] = array[2] + 1;

    return array;
};
