import Palette from '@/styles/Themes/Palette';
import styled, { css, keyframes } from 'styled-components';

export const ldsEllipsis1 = () => keyframes`

0% {
  transform: scale(0);
}
100% {
  transform: scale(1);
}
`;
export const ldsEllipsis2 = () => keyframes`
0% {
  transform: translate(0, 0);
}
100% {
  transform: translate(24px, 0);
}
`;
export const ldsEllipsis3 = () => keyframes`
0% {
  transform: scale(1);
}
100% {
  transform: scale(0);
}
`;

export const Wrapper = styled.div<{ noMargin?: boolean }>`
    display: flex;
    justify-content: center;
    ${({ noMargin }) =>
        !noMargin &&
        css`
            margin-bottom: 40px;
        `}
`;

export const LdsEllipsis = styled.div<{ isWhite?: boolean }>`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 20px;
    div {
        position: absolute;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: ${({ isWhite }) => (isWhite ? Palette.white.normal : Palette.gradient.light)};
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    div:nth-child(1) {
        left: 8px;
        animation: ${ldsEllipsis1} 0.6s infinite;
    }
    div:nth-child(2) {
        left: 8px;
        animation: ${ldsEllipsis2} 0.6s infinite;
    }
    div:nth-child(3) {
        left: 32px;
        animation: ${ldsEllipsis2} 0.6s infinite;
    }
    div:nth-child(4) {
        left: 56px;
        animation: ${ldsEllipsis3} 0.6s infinite;
    }
`;
