import { useRouter } from 'next/router';
import { memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { SERVER_FORMAT_DATE } from '@/common/app/constants/timeConstants';

import { IActivityItem, TImageItem } from '@/common/service/api/Activity/Activity.domain';
import { TFaqItem } from '@/common/service/api/FAQ/FAQ.domain';
import { ActivityProvider } from '@/common/app/contexts/ActivityContext';
import { TPublicStories } from '@/common/service/api/Stories/Stories.domain';
import { useMedia } from '@/common/service/hooks/useMedia';
import { getCS, getLS, removeLS, saveCS } from '@/common/service/storage';
import { Button, IAccordionTab } from '@/common/ui';
import { GiftCard } from '@/common/ui/cards/GiftCard/GiftCard';
import { ReviewsCard } from '@/common/ui/cards/ReviewsCard';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';
import { ReviewWithURL } from '@/entities/Activity/domain/ActivityPage.domain';
import { Container } from '@/common/ui/containers/Container/Container';
import { useDateContext, useWishlistContext } from '@/common/app/contexts';
import {
    IDestinationCategory,
    IDestinationList,
} from '@/entities/Destination/domain/Destination.domain';
import { IBreadcrumbsItem } from '@/shared/Breadcrumbs/domain/Breadcrumbs.domain';
import Layout from '@/shared/Layout/ui/Layout';
import Paragraph from '@/shared/Paragraph/ui/Paragraph';
import { TalkToUs } from '@/shared/TalkToUs/ui/TalkToUs';
import { addDays, format } from 'date-fns';
import { ActivityDetail } from './ActivityDetail/ActivityDetail';
import * as S from './ActivityPage.styles';
import { ActivitySubtitle } from './ActivitySubtitle/ActivitySubtitle';
import { ActivityTabs } from './ActivityTabs/ActivityTabs';
import Calendar from './Calendar/Calendar';
import ActivityPageMeta from '../app/Meta/Meta';
import URL, { buildRootUrl } from '@/common/app/utils/urlsUtils';
import { StickyFooter } from './StickyFooter/StickyFooter';
import { trackProductDetail } from '@/common/app/utils/iterable';
import { AnalyticsInstance } from '@/common/service/analytics';
import { ActivityArticle } from '@/common/service/analytics/Analytics.types';
import { getContent } from './ActivityTabs/activityTabs/getContent';
import { ACTIVITY_TABS } from './ActivityTabs/activityTabs/constants';
import { ActivityFeaturedActivities } from './ActivityFuturesActivities';
import { useInView } from 'react-intersection-observer';
import { Tomorrow } from '@/shared/images/icons/Tomorrow';
import { AlsoViewedActivities } from '@/shared/FeaturedActivities/ui/AlsoViewedActivities';
import { trackViewItem } from '@/shared/Analytics/GA4';
import { readDetailsData } from '@/shared/Analytics/GA4/helpers';
import Link from 'next/link';
import { TLandmarkShort } from '@/screens/LandmarkMainPage/domain/Landmarks.domain';
import { LandmarksCarousel } from './LandmarksCarousel/LandmarksCarousel';

const INIT_DATALAYER_EVENTS = 10;

export type ActivityPageProps = {
    breadcrumbs: IBreadcrumbsItem[];
    activity: IActivity;
    userImages: TImageItem[];
    stories: TPublicStories;
    activityReviews: ReviewWithURL[];
    currentDestination: IDestinationList;
    currentCategory: IDestinationCategory | null;
    structuredData: string;
    faq: TFaqItem[];
    isGiftCard: boolean;
    featuredActivitiesTitle: string;
    alsoViewedActivities: IActivityItem[];
    isTour?: boolean;
    landmarks: TLandmarkShort[];
};

const getActivityData = (data: string) => {
    const activityData = JSON.parse(data);
    return activityData.text ?? activityData;
};

const pick = (obj: Record<string, unknown>, arr: string[]) => {
    return Object.keys(obj).reduce((acc, key) => {
        if (arr.includes(key)) {
            return {
                ...acc,
                [key]: obj[key],
            };
        }

        return acc;
    }, {});
};

const ActivityPageContainer = ({
    breadcrumbs,
    activity,
    currentCategory,
    currentDestination,
    userImages,
    structuredData,
    isGiftCard,
    featuredActivitiesTitle,
    alsoViewedActivities,
    isTour,
    landmarks,
}: ActivityPageProps): ReactElement => {
    const router = useRouter();
    const { wishlist, addOrRemoveWishlist } = useWishlistContext();
    const { date } = useDateContext();
    const fromLandingScrollTo = getLS('fromLandingScrollTo');

    const refCalendar = useRef<HTMLDivElement | null>(null);
    const refReviews = useRef<HTMLDivElement>(null);
    const refDescription = useRef<HTMLDivElement>(null);
    const { ref: inViewRef, inView } = useInView({
        threshold: 0.1,
    });

    const refCalendarContainer = useCallback(
        (node: HTMLDivElement) => {
            refCalendar.current = node;
            inViewRef(node);
        },
        [inViewRef]
    );
    const isMobileView = useMedia('(max-width: 499px)');
    const [isOpenTalkToUs, setOpenTalkToUs] = useState(false);
    const [indexOpen, setIndexOpen] = useState<number | null>(0);
    const [isGA4Tracked, setIsGA4Tracked] = useState(false);

    const specialStatus = isMobileView
        ? activity.special_status.mobile
        : activity.special_status.desktop;

    const pandemicData = () => {
        const list = activity.pandemic_meta_data.map(
            (item, index) => `<li key=${index}>${item}</li>`
        );
        return `<ul>${list.join('')}</ul>`;
    };

    const getActivityInfo = () => {
        const data = getActivityData(activity.data);
        if (activity.pandemic_meta_data.length) {
            return { ...data, pandemic_meta_data: pandemicData() };
        }
        return data;
    };

    const activityData = getActivityInfo();
    const ActivityReviews = activity.reviews;
    const reviewsCount = activity.rate_count || 0;
    const linkToPartner =
        `/partners/${currentDestination.state.code}/${currentDestination.slug}/${activity.partner_slug}/${activity.partner_id}`.toLowerCase();

    const reviewsUrl = useMemo(() => {
        const today = new Date();

        const searchFrom = date?.from ? date.from : format(today, SERVER_FORMAT_DATE);
        const searchTo = date?.to ? date.to : format(addDays(today, 7), SERVER_FORMAT_DATE);

        const searchDate = searchFrom && searchTo ? `?from=${searchFrom}&to=${searchTo}` : '';
        const url = activity.url || '/';
        return url && url !== '/' ? `${url.split('?')[0]}reviews/${searchDate}` : '/';
    }, [activity.url, date.from, date.to]);

    const addedToWishlist = wishlist.includes(activity.id);

    //const linkToPartner = `/partners/${activity.partner_slug}/${activity.partner_id}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const pickAttrs = [
        'link_main',
        'url',
        'link_photo',
        'image_url',
        'slug',
        'rate_count',
        'reviews_cnt',
        'name',
        'price_strike_out',
        'price',
        'rating',
        'rating_stars',
        'duration',
        'analytics_data',
        'name',
        'id',
    ];

    const dataLayerExist =
        typeof window !== 'undefined' && window.dataLayer?.length > INIT_DATALAYER_EVENTS;

    const gaHandler = useCallback(() => {
        if (router.isReady && !isGA4Tracked) {
            const data = readDetailsData();
            console.log('data 321', data);
            const coupon = getCS('ts_promocode');
            const activityData = {
                item_id: activity.id,
                item_name: activity.name,
                item_brand: activity.title,
                item_brand_id: activity.partner_id,
                item_category: activity.city_name,
                item_category_id: activity.city_id,
                item_category2: activity.activity_categories[0].name,
                item_category2_id: activity.activity_categories[0].id,
                location_id: activity.google.place_id,
                quantity: 1,
                ...(coupon ? { coupon: `${coupon}` } : {}),
            };

            trackViewItem({
                currency: 'USD',
                items: [
                    {
                        ...activityData,
                        index: data?.index || 1,
                        ...(activity?.price && activity?.price_strike_out
                            ? {
                                  price: activity.price,
                                  discount:
                                      Math.round(
                                          (activity.price_strike_out - activity.price) * 100
                                      ) / 100,
                              }
                            : { price: activity.price }),
                        ...(data?.item_category3 ? { item_category3: data.item_category3 } : {}),
                        ...(data?.item_list_name ? { item_list_name: data.item_list_name } : {}),
                        ...(data?.item_list_id ? { item_list_id: data.item_list_id } : {}),
                    },
                ],
                value: activity.price,
            });

            setIsGA4Tracked(true);
        }
    }, [activity, router, isGA4Tracked]);

    useEffect(() => {
        gaHandler();
    }, [gaHandler]);

    useEffect(() => {
        if (dataLayerExist) {
            AnalyticsInstance.product.detail(
                AnalyticsInstance.creators.createProductDetailFromArticle({
                    categoryName: AnalyticsInstance.creators.createCategoryNameFromArticle(
                        activity as ActivityArticle
                    ),
                })(activity as ActivityArticle),
                currentCategory?.name || '',
                { activity, destination: currentDestination, category: currentCategory }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataLayerExist]);

    useEffect(() => {
        trackProductDetail(activity, currentDestination, currentCategory);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const cur = getCS('Recently_Viewed');
        let newValue = [pick(activity, pickAttrs)];
        if (Array.isArray(cur)) {
            cur.map((item) => {
                if (item?.id === activity?.id) {
                    newValue = [];
                }
            });
        }

        saveCS('Recently_Viewed', newValue.concat(Array.isArray(cur) ? cur : []).slice(0, 8));
    }, [activity, pickAttrs]);

    const handleGetTickets = () => {
        refCalendar.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const handleScrollToReviews = () => {
        refReviews.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const handleScrollToDescription = () => {
        refDescription.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    useEffect(() => {
        if (router.isReady) {
            if (fromLandingScrollTo === 'calendar') {
                setTimeout(handleGetTickets, 300);
            }
            if (fromLandingScrollTo === 'reviews') {
                setTimeout(handleScrollToReviews, 300);
            }
            if (fromLandingScrollTo === 'description') {
                setTimeout(handleScrollToDescription, 300);
            }

            removeLS('fromLandingScrollTo');
        }
    }, [fromLandingScrollTo, router.isReady]);

    const redirectToReviewsPage = () => {
        return router.push(reviewsUrl);
    };

    const onCloseTalkToUsClick = () => {
        setOpenTalkToUs(false);
    };

    const onTalkToUsClick = () => {
        setOpenTalkToUs(true);
    };

    const handleWishlist = () => {
        if (addOrRemoveWishlist) addOrRemoveWishlist(activity, 1, activity.name, activity.slug);
    };

    const pageActivity = {
        previewDescription: activity.previewDescription,
        name: activity.name,
        url: activity.url,
    };

    const openGraphImage =
        activity && `${URL.image}/activity/${activity.id}/1080x1080/${activity.slug}.jpg`;

    const handleClickOnTab = (index: number) => {
        if (index === indexOpen) {
            setIndexOpen(null);
        } else {
            setIndexOpen(index);
        }
    };

    const lastBreadcrumb = breadcrumbs.slice(-2);

    const tabs = ACTIVITY_TABS.map(({ title, key, id }) =>
        getContent(title, activityData, key, id)
    ).filter((item) => item !== undefined) as IAccordionTab[];

    const url = pageActivity.url.split('?')[0];
    const rootUrl = url ? buildRootUrl(url) : '';

    return (
        <Layout breadcrumbs={breadcrumbs}>
            <ActivityProvider activity={activity} is_ttd={isTour}>
                <ActivityPageMeta
                    pageMeta={activity.meta}
                    pageActivity={pageActivity}
                    openGraphImage={openGraphImage}
                    pageStructuredData={structuredData}
                    noindex={isTour}
                    rootUrl={rootUrl}
                />
                <div data-test-id="Details">
                    <Container>
                        {isOpenTalkToUs && <TalkToUs onClose={onCloseTalkToUsClick} />}
                        <S.Title data-test-id="Details_title">{activity.name}</S.Title>
                        <ActivitySubtitle
                            destination={{
                                name: currentDestination.name,
                                code: currentDestination.state.code,
                            }}
                            ratings={activity.rating_stars}
                            reviewsCount={+reviewsCount}
                            ratingsUrl={''}
                            onTalkToUsClick={onTalkToUsClick}
                            scrollToReviews={handleScrollToReviews}
                            isGiftCard={isGiftCard}
                            isTour={isTour}
                        />
                        <ActivityDetail
                            name={activity.name}
                            videos={activity.videos}
                            slug={activity.slug}
                            activityId={activity.id}
                            userImages={userImages}
                            addedToWishList={addedToWishlist}
                            onGetTickets={handleGetTickets}
                            onWishlist={handleWishlist}
                            activityStrikeOut={activity.price_strike_out}
                            isGiftCard={isGiftCard}
                            defaultPrice={activity.price}
                            totalPurchased={activity.total_purchased}
                            recentPurchased={activity.recent_purchased}
                            rootUrl={rootUrl}
                        />
                        {specialStatus && (
                            <S.SpecialStatus data-test-id="special-status">
                                {specialStatus}
                            </S.SpecialStatus>
                        )}
                        {isTour && (
                            <S.Partner data-test-id="Details_partner_link">
                                By: &nbsp;
                                <Link href={linkToPartner} passHref>
                                    <S.PartnerLink>{activity.title}</S.PartnerLink>
                                </Link>
                            </S.Partner>
                        )}
                        {activityData && activityData.description && (
                            <S.ActivityDescription ref={refDescription}>
                                <Paragraph
                                    id="activity_paragraph"
                                    as="div"
                                    innerHtml={activityData.description}
                                />
                                {activity.duration && (
                                    <S.Duration>
                                        <Tomorrow />
                                        Duration {activity.duration}
                                    </S.Duration>
                                )}
                            </S.ActivityDescription>
                        )}
                        <AlsoViewedActivities
                            currentActivity={activity}
                            alsoViewedActivities={alsoViewedActivities}
                            currentCategory={currentCategory}
                            currentDestination={currentDestination}
                        />
                        <ActivityTabs
                            handleClickOnTab={handleClickOnTab}
                            indexOpen={indexOpen}
                            tabs={tabs}
                            latitude={activity.latitude}
                            longitude={activity.longitude}
                            address={activity.address}
                        />

                        {!!landmarks.length && (
                            <LandmarksCarousel
                                landmarks={landmarks}
                                currentDestination={currentDestination}
                            />
                        )}
                    </Container>
                </div>

                <S.CalendarWrapper ref={refCalendarContainer}>
                    <Calendar
                        handleClickOnTab={handleClickOnTab}
                        title={`${activity.name}`}
                        isGiftCard={isGiftCard}
                        lastBreadcrumb={lastBreadcrumb}
                        reservationIndex={tabs.findIndex((item) => item.id === 'agreement')}
                    />
                </S.CalendarWrapper>

                {!isGiftCard && <GiftCard />}

                <ActivityFeaturedActivities
                    currentActivity={activity}
                    title={featuredActivitiesTitle}
                    isGiftCard={isGiftCard}
                />

                {!isGiftCard && (
                    <>
                        <Container data-test-id="Details_reviews">
                            {ActivityReviews.length > 0 ? (
                                <S.ActivityReviews ref={refReviews}>
                                    <S.ActivityReviewsTitle>
                                        Don&apos;t take our word for it
                                    </S.ActivityReviewsTitle>
                                    <S.ActivityReviewsSubTitle>
                                        Verified Traveler Reviews
                                    </S.ActivityReviewsSubTitle>
                                    <S.ReviewCardContainer>
                                        {ActivityReviews.slice(0, 4).map((review) => {
                                            return <ReviewsCard key={review.id} review={review} />;
                                        })}
                                    </S.ReviewCardContainer>
                                    {ActivityReviews.length > 2 && (
                                        <Button
                                            variant={'primary'}
                                            onClick={() => redirectToReviewsPage()}
                                        >
                                            See all reviews
                                        </Button>
                                    )}
                                </S.ActivityReviews>
                            ) : (
                                <S.NewToTS>Brand new to Tripshock</S.NewToTS>
                            )}
                        </Container>
                    </>
                )}
                <StickyFooter
                    activity={activity}
                    handleScrollToReviews={handleScrollToReviews}
                    handleGetTickets={handleGetTickets}
                    isInView={inView}
                    isGiftCard={isGiftCard}
                    defaultPrice={activity.price}
                />
            </ActivityProvider>
        </Layout>
    );
};

export const ActivityPage = memo(ActivityPageContainer);
