import styled, { css } from 'styled-components';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { borders, radius } from '@/styles/Themes/Metrics';

const ANIMATION_DURATION = '1.6s';

const HEADER_COLOR = css`
    box-shadow: ${cssVars.shadow_card_default};
    background-color: ${cssVars.bg_white_blue};
`;

const BODY_COLOR = css`
    background-color: ${cssVars.bg_gray4_dark2};
`;

const SELECTOR_COLOR = css`
    background-color: ${cssVars.bg_ash_blueDark};
`;

export const Wrapper = styled.div`
    box-shadow: ${cssVars.shadow_card_default};
    border-radius: ${radius.small};
`;

export const Header = styled.div`
    position: relative;
    ${SELECTOR_COLOR}
    border-radius: 15px 15px 0 0;
    padding: 30px 10px;

    @keyframes skeleton-loading {
        0% {
            background-color: ${cssVars.bg_white_blue};
        }
        100% {
            background-color: ${cssVars.bg_ash_blueDark};
        }
    }

    animation: skeleton-loading ${ANIMATION_DURATION} linear infinite alternate;

    ${Screen.ipad} {
        padding: 44px 32px 41px;
    }
    ${Screen.desktop} {
        padding: 42px 47px 40px;
    }
`;

export const CalendarLine = styled.div`
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px;
    *:first-child {
        margin: 0 20px 0 0;
    }

    *:last-child {
        margin: 0 0 0 20px;
    }

    *:nth-child(2) {
        max-width: 70px;
        max-height: 20px;
    }

    ${Screen.ipad} {
        width: 70%;
        margin: 0 auto 35px;

        *:first-child {
            margin: 0 52px 0 0;
        }

        *:nth-child(2) {
            max-width: 76px;
            max-height: 22px;
        }

        *:last-child {
            margin: 0 0 0 52px;
        }
    }
    ${Screen.desktop} {
        height: 30px;
        width: 60%;
    }
`;

export const NextBtn = styled.div`
    ${HEADER_COLOR}
    width: 95px;
    height: 36px;
    border-radius: 36px;

    ${Screen.ipad} {
        width: 145px;
        height: 30px;
    }

    ${Screen.desktop} {
        width: 145px;
        height: 30px;
    }
`;

export const DaysLine = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;

    ${Screen.ipad} {
        flex-wrap: nowrap;
    }
`;

export const FirstLine = styled.div`
    display: grid;
    grid-template: 1fr / repeat(3, 1fr);
    grid-gap: 10px;
    width: 72%;
    height: auto;
    margin: 0 auto 15px;

    ${Screen.ipad} {
        margin: 0 10px 0 0;
        grid-template: 1fr / repeat(3, 1fr);
        align-items: end;
        width: 74%;
    }

    ${Screen.desktop} {
        margin: 0 20px 0 0;
        grid-gap: 20px;
    }
`;

export const SecondLine = styled.div`
    display: grid;
    grid-template: 1fr / repeat(4, 1fr);
    grid-gap: 10px;
    width: 100%;
    align-items: end;

    ${Screen.desktop} {
        grid-gap: 20px;
    }
`;

export const Price = styled.div`
    ${HEADER_COLOR}
    border-radius: ${radius.small};
    height: 16px;
    max-width: 100%;
    margin: 0 12px 2px;

    ${Screen.ipad} {
        height: 18px;
    }
`;

export const Box = styled.div`
    ${HEADER_COLOR}
    border-radius: ${radius.small};
    width: 100%;
    height: 72px;
    margin-top: 6px;

    ${Screen.ipad} {
        height: 90px;
        margin-top: 8px;
    }

    ${Screen.ipad} {
        height: 100px;
        margin-top: 14px;
    }
`;

export const Body = styled.div`
    background-color: ${cssVars.bg_white_dark3};
    padding: 15px 20px 50px;
    position: relative;

    @keyframes loading {
        0% {
            background-color: ${cssVars.bg_ash_blueDark};
        }
        100% {
            background-color: ${cssVars.bg_white_dark3};
        }
    }

    animation: loading ${ANIMATION_DURATION} linear infinite alternate;

    ${Screen.ipad} {
        padding: 20px 30px 60px;
    }
    ${Screen.desktop} {
        padding: 19px 41px 55px;
    }
`;

export const DateLine = styled.div`
    padding-bottom: 15px;
    border-bottom: ${borders.small} ${cssVars.bg_gray4_dark2};

    ${Screen.ipad} {
        padding-bottom: 20px;
    }
`;

export const Text = styled.div<{ mWidth?: number; dWidth?: number; margin?: boolean }>`
    ${BODY_COLOR}
    height: 20px;
    width: ${({ mWidth }) => `${mWidth || 210}px`};
    border-radius: ${radius.small};
    ${({ margin }) =>
        margin &&
        css`
            margin: 22px 0 15px;
        `}

    ${Screen.ipad} {
        height: 22px;
        width: ${({ dWidth }) => `${dWidth || 250}px`};
    }
`;

export const Selector = styled.div`
    max-width: 100%;
    height: 50px;
    ${SELECTOR_COLOR}
    margin: 0 20px;
    border-radius: ${radius.large};

    ${Screen.desktop} {
        max-width: 45%;
    }
`;

export const Radio = styled.div`
    ${BODY_COLOR}
    max-width: 100%;
    height: 20px;
    position: relative;
    margin: 0 20px 20px 50px;
    border-radius: ${radius.large};

    &:before {
        content: '';
        height: 20px;
        width: 20px;
        border-radius: ${radius.large};
        ${SELECTOR_COLOR}
        position: absolute;
        left: -30px;
    }

    ${Screen.ipad} {
        max-width: 390px;
    }
`;

export const Textarea = styled.div`
    ${SELECTOR_COLOR}
    max-width: 100%;
    height: 80px;
    border-radius: ${radius.small};
    margin: 0 20px 30px;
`;

export const AddButton = styled.div`
    ${BODY_COLOR}
    max-width: 100%;
    height: 50px;
    border-radius: ${radius.large};
    margin: 0 0 30px;

    ${Screen.desktop} {
        margin: 0;
        position: absolute;
        bottom: 56px;
        right: 40px;
        width: 250px;
    }
`;
