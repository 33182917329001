/* eslint-disable sonarjs/cognitive-complexity */
import { memo, ReactElement } from 'react';
import { Rating } from '@/entities/Activity/ui/Rating';
import { Button } from '@/common/ui';
import * as S from './StickyFooter.styles';
import { StickyFooterProps } from './StickyFooter.domain';
import { useActivityContext } from '@/common/app/contexts/ActivityContext';
import { getNormalisedPrice } from '@/common/app/utils/getNormalisedPrice';

export const StickyFooter = ({
    activity,
    handleScrollToReviews,
    handleGetTickets,
    isInView,
    isGiftCard,
    defaultPrice,
}: StickyFooterProps): ReactElement => {
    const { status, shortAvail, submitAddToCart } = useActivityContext();

    const ratingWithComma = Number(activity.rate_count).toLocaleString('en-IN');
    const priceWithComma = shortAvail?.price
        ? getNormalisedPrice(shortAvail.price)
        : getNormalisedPrice(defaultPrice || 0);
    const priceStrikeOutWithComma =
        shortAvail?.price_strike_out &&
        shortAvail?.price &&
        shortAvail.price_strike_out > shortAvail.price
            ? getNormalisedPrice(shortAvail?.price_strike_out)
            : 0;

    if (status.isValid) {
        return (
            <S.StiсkyFooter data-test-id="Sticky_footer">
                <S.SecondStiсkyFooterContent isInView={true}>
                    <S.RatingSection>
                        {!status.isValid ? (
                            <S.StatusMessage>{status.message}</S.StatusMessage>
                        ) : (
                            <S.StatusMessage>{`Great. You're ready to Add to your cart!`}</S.StatusMessage>
                        )}
                    </S.RatingSection>
                    <S.ButtonSection>
                        <Button
                            onClick={submitAddToCart}
                            variant="primary"
                            disabled={!status.isValid || status.isLoading}
                        >
                            {status.editModeID ? 'Edit tickets' : 'Add to cart'}
                        </Button>
                    </S.ButtonSection>
                </S.SecondStiсkyFooterContent>
            </S.StiсkyFooter>
        );
    }
    return (
        <S.StiсkyFooter>
            <S.StiсkyFooterContent isInView={isInView}>
                <S.RatingSection>
                    {!!priceWithComma && (
                        <S.PriceMobile>
                            From&nbsp;
                            {activity.price_strike_out && (
                                <S.OldPrice>{priceStrikeOutWithComma} </S.OldPrice>
                            )}
                            &nbsp;
                            <S.NewPrice>{priceWithComma}</S.NewPrice>
                        </S.PriceMobile>
                    )}
                    <Rating rating={activity.rating_stars} />
                    {Number(activity.rate_count) !== 0 ? (
                        <S.ReviewsOrNew onClick={handleScrollToReviews}>
                            {ratingWithComma}&nbsp;
                            {ratingWithComma > '1' ? 'Ratings' : 'Rating'}
                        </S.ReviewsOrNew>
                    ) : (
                        <S.ReviewsOrNew onClick={handleScrollToReviews}>
                            New to Tripshock
                        </S.ReviewsOrNew>
                    )}
                </S.RatingSection>
                {!!priceWithComma && (
                    <S.PriceSection>
                        From&nbsp;
                        {!!priceStrikeOutWithComma && (
                            <S.OldPrice>{priceStrikeOutWithComma} </S.OldPrice>
                        )}
                        &nbsp;
                        <S.NewPrice>{priceWithComma}</S.NewPrice>
                    </S.PriceSection>
                )}
                <S.ButtonSectionFirst isValid={status.isValid} isInView={isInView}>
                    <Button onClick={() => handleGetTickets()} variant="primary">
                        {isGiftCard ? 'Purchase' : 'Get Tickets'}
                    </Button>
                </S.ButtonSectionFirst>
            </S.StiсkyFooterContent>

            <S.SecondStiсkyFooterContent isInView={isInView}>
                <S.RatingSection>
                    {!status.isValid ? (
                        <a
                            href={
                                status.link === 'tickets'
                                    ? '#tickets'
                                    : status.link === 'cancellation-info'
                                    ? '#cancellation-info'
                                    : status.link === 'additional_options'
                                    ? '#additional-options'
                                    : status.link === 'min-tickets'
                                    ? '#min-tickets'
                                    : status.link === 'availability'
                                    ? '#availability'
                                    : ''
                            }
                        >
                            {status.message}
                        </a>
                    ) : (
                        `Great. You're ready to Add to your cart!`
                    )}
                </S.RatingSection>
                <S.ButtonSection>
                    <Button
                        onClick={submitAddToCart}
                        variant="primary"
                        disabled={!status.isValid || status.isLoading}
                    >
                        {status.editModeID ? 'Edit tickets' : 'Add to cart'}
                    </Button>
                </S.ButtonSection>
            </S.SecondStiсkyFooterContent>
        </S.StiсkyFooter>
    );
};

export default memo(StickyFooter);
