export const LowestPrice = ({ className }: { className?: string }) => {
    return (
        <svg
            className={className}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.66422 6.37913C9.66422 6.09194 9.43141 5.85913 9.14422 5.85913C8.85703 5.85913 8.62422 6.09194 8.62422 6.37913V6.88911C8.37284 6.87823 8.12159 6.91529 7.88392 6.99841C7.63765 7.08454 7.4108 7.21842 7.21637 7.39238C7.02194 7.56634 6.86376 7.77696 6.75088 8.01218C6.63801 8.24739 6.57266 8.50256 6.55859 8.76308L7.07784 8.79112L6.55878 8.82236C6.59031 9.34634 6.82835 9.83642 7.22068 10.1852C7.60901 10.5303 8.11714 10.7092 8.63565 10.6836H9.66022C9.66905 10.6836 9.67787 10.6834 9.68668 10.6829C9.93727 10.6701 10.1828 10.7568 10.3698 10.9241C10.549 11.0845 10.6605 11.3064 10.6828 11.5451C10.6605 11.7837 10.549 12.0056 10.3698 12.166C10.1828 12.3333 9.93727 12.42 9.68668 12.4072C9.67787 12.4068 9.66905 12.4065 9.66022 12.4065H9.16815C9.16022 12.4062 9.15224 12.406 9.14422 12.406C9.1362 12.406 9.12822 12.4062 9.12029 12.4065H7.76407C7.47688 12.4065 7.24407 12.6394 7.24407 12.9265C7.24407 13.2137 7.47688 13.4465 7.76407 13.4465H8.62422V13.9553C8.62422 14.2425 8.85703 14.4753 9.14422 14.4753C9.43141 14.4753 9.66422 14.2425 9.66422 13.9553V13.4472C10.178 13.4655 10.6795 13.2844 11.0632 12.941C11.4545 12.5909 11.6918 12.1004 11.7233 11.5763L11.2042 11.5451L11.7233 11.5138C11.6918 10.9897 11.4545 10.4992 11.0632 10.1491C10.6754 9.80213 10.1674 9.62083 9.64798 9.64358H8.62185C8.6119 9.64358 8.60195 9.64386 8.59202 9.64443C8.34285 9.65875 8.09816 9.57367 7.91162 9.40786C7.73249 9.24863 7.62101 9.02744 7.59915 8.78952C7.60888 8.67596 7.63913 8.56503 7.68851 8.46213C7.74218 8.35029 7.81739 8.25014 7.90984 8.16743C8.00228 8.08471 8.11015 8.02106 8.22724 7.98011C8.34433 7.93916 8.46836 7.92172 8.59221 7.92879C8.60208 7.92935 8.61196 7.92964 8.62185 7.92964H10.518C10.8052 7.92964 11.038 7.69682 11.038 7.40964C11.038 7.12245 10.8052 6.88964 10.518 6.88964H9.66422V6.37913Z"
                fill="var(--svg-gray1-white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.94702 4.68797C7.21118 3.95243 8.69321 3.68337 10.1352 3.92761C11.5773 4.17185 12.8881 4.91393 13.8395 6.02474C14.7902 7.13468 15.322 8.54256 15.3426 10.0038V12.464L14.2166 11.338C14.0135 11.135 13.6843 11.135 13.4812 11.338C13.2781 11.5411 13.2781 11.8704 13.4812 12.0734L15.4857 14.0779C15.5834 14.1756 15.7159 14.2304 15.854 14.2303C15.9921 14.2301 16.1245 14.175 16.2219 14.0771L18.2174 12.0726C18.42 11.8691 18.4193 11.5398 18.2158 11.3372C18.0122 11.1346 17.683 11.1353 17.4804 11.3389L16.3826 12.4416V10.0002H16.3826L16.3825 9.99313C16.3594 8.28742 15.7391 6.64378 14.6294 5.34819C13.5197 4.05261 11.9908 3.18708 10.3089 2.90221C8.627 2.61734 6.89844 2.93116 5.42399 3.78906C3.94954 4.64696 2.82253 5.99464 2.23904 7.59762C1.65556 9.2006 1.65254 10.9574 2.2305 12.5624C2.80846 14.1674 3.93083 15.5189 5.40232 16.3819C6.87381 17.2449 8.60128 17.5646 10.2842 17.2856C11.9671 17.0065 13.4988 16.1462 14.613 14.8545C14.8006 14.637 14.7763 14.3086 14.5589 14.1211C14.3414 13.9335 14.013 13.9577 13.8255 14.1752C12.8702 15.2827 11.5569 16.0203 10.114 16.2596C8.67115 16.4988 7.19006 16.2247 5.92844 15.4848C4.66681 14.7449 3.70452 13.5861 3.20899 12.21C2.71346 10.834 2.71605 9.32771 3.21631 7.95335C3.71658 6.57899 4.68286 5.42352 5.94702 4.68797Z"
                fill="var(--svg-gray1-white"
            />
        </svg>
    );
};
