/* eslint-disable sonarjs/cognitive-complexity */
import { Inventory, Schedule, Ticket } from '@/common/service/api/Availability/Availability.domain';

type TInventories = {
    [key: string]: {
        duration: string;
        count: number;
    };
};

export const processTicketsInventories = (
    ticketSelection: { [key: number]: number },
    scheduleInfo?: Schedule
): Ticket[] => {
    if (!scheduleInfo) {
        return [];
    }
    try {
        const finalTickets: Ticket[] = JSON.parse(JSON.stringify(scheduleInfo.tickets));
        const inventories: Inventory[] = JSON.parse(JSON.stringify(scheduleInfo.inventories));

        if (scheduleInfo.inventories?.length) {
            const ticketSelectionMap = Object.entries(ticketSelection);

            const haveSelection = ticketSelectionMap.some((value) => value[1] > 0);

            if (haveSelection) {
                const invTotals: TInventories = {};
                const invSelected: TInventories = {};

                inventories.forEach((inventory) => {
                    const currentDuration = inventory.duration as string;

                    if (typeof invTotals[currentDuration] === 'undefined') {
                        invTotals[currentDuration] = {
                            duration: currentDuration,
                            count: 0,
                        };
                    }

                    invTotals[currentDuration].count += inventory.avail;
                });

                ticketSelectionMap.forEach(([id, value]) => {
                    const selectedTicket = finalTickets.find(
                        ({ id: ticketID }) => ticketID === +id
                    );

                    if (selectedTicket) {
                        const currDuration = selectedTicket.duration;

                        if (value > 0) {
                            if (typeof invSelected[currDuration] === 'undefined') {
                                invSelected[currDuration] = {
                                    duration: currDuration,
                                    count: value,
                                };
                            } else {
                                invSelected[currDuration].count += value;
                            }
                        }
                    }
                });

                Object.values(invSelected).forEach((selectedInventory) => {
                    let spreadToNull = 0;

                    if (typeof invTotals[selectedInventory.duration] !== 'undefined') {
                        if (
                            invTotals[selectedInventory.duration].count >= selectedInventory.count
                        ) {
                            invTotals[selectedInventory.duration].count -= selectedInventory.count;
                        } else {
                            spreadToNull =
                                selectedInventory.count -
                                invTotals[selectedInventory.duration].count;
                            invTotals[selectedInventory.duration].count = 0;
                        }
                    } else {
                        spreadToNull = selectedInventory.count;
                    }

                    if (spreadToNull !== 0) {
                        if (selectedInventory.duration === null) {
                            Object.values(invTotals).forEach((invTotal) => {
                                if (spreadToNull > 0 && invTotal.duration) {
                                    if (invTotal.count >= spreadToNull) {
                                        invTotals[invTotal.duration].count -= spreadToNull;
                                    } else {
                                        spreadToNull -= invTotal.count;
                                        invTotals[invTotal.duration].count = 0;
                                    }
                                }
                            });
                        } else {
                            inventories.forEach((inventory) => {
                                if (
                                    spreadToNull > 0 &&
                                    inventory.duration === null &&
                                    inventory.avail > 0
                                ) {
                                    if (inventory.avail > spreadToNull) {
                                        invTotals[inventory.duration].count -= inventory.avail;

                                        if (
                                            typeof invTotals[selectedInventory.duration] ===
                                            'undefined'
                                        ) {
                                            invTotals[selectedInventory.duration] = {
                                                duration: selectedInventory.duration,
                                                count: inventory.avail - spreadToNull,
                                            };
                                        } else {
                                            invTotals[selectedInventory.duration].count +=
                                                inventory.avail - spreadToNull;
                                        }

                                        spreadToNull = 0;

                                        inventory.avail -= spreadToNull;
                                        inventory.duration = selectedInventory.duration;
                                    } else {
                                        invTotals[inventory.duration].count -= inventory.avail;

                                        spreadToNull -= inventory.avail;

                                        inventory.avail = 0;
                                        inventory.duration = selectedInventory.duration;
                                    }
                                }
                            });
                        }
                    }
                });

                finalTickets.forEach((finalTicket) => {
                    if (finalTicket.avail) {
                        const selectedAlready = ticketSelectionMap.find(
                            ([id]) => +id === finalTicket.id
                        );
                        let availMax =
                            typeof selectedAlready === 'undefined' ? 0 : selectedAlready[1];

                        if (
                            finalTicket.duration !== null &&
                            typeof invTotals[finalTicket.duration] !== 'undefined'
                        ) {
                            availMax += invTotals[finalTicket.duration].count;
                        }

                        if (typeof invTotals.null !== 'undefined') {
                            availMax += invTotals.null.count;
                        }

                        if (finalTicket.avail > availMax) {
                            finalTicket.avail = availMax;
                        }
                    }
                });
            }
        }

        return finalTickets;
    } catch (err) {
        console.log(err);
        if (scheduleInfo && Array.isArray(scheduleInfo.tickets)) {
            return scheduleInfo.tickets;
        }

        return [];
    }
};
