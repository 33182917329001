import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import ErrorMessage from '@/shared/images/icons/ErrorMessage';
import { borders } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';

export const ErrorText = styled.div`
    ${Text.Mobile.normal.semibold};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: ${Palette.state.error};
    border-radius: 10px;
    border: ${borders.small} ${cssVars.bg_gray4_dark2};
    color: ${Palette.ash.normal};
    width: 90%;
    padding: 5px;
    box-shadow: ${cssVars.shadow_card_default};
    margin: 0 auto 16px auto;

    ${Screen.ipad} {
        ${Text.Ipad.normal.semibold};
        width: 94%;
    }

    > svg {
        > circle {
            fill: ${Palette.white.normal};
        }
        > path {
            fill: ${Palette.state.error};
        }
    }
`;

export const ErrorIcon = styled(ErrorMessage)`
    margin: 0 10px;
`;
