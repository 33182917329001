import { Accordion } from '@/common/ui/containers/Accordion/Accordion';
import { cssVars } from '@/styles/Themes/cssVars';
import Text from '@/styles/Themes/Text';
import styled from 'styled-components';
import Screen from '@/styles/Themes/Screen';
import { radius } from '@/styles/Themes/Metrics';
import { Palette } from '@/styles/Themes/Palette';

export const CalendarWrapper = styled.div`
    padding: 25px 0 50px;
    scroll-margin-top: 30px;
    ${Screen.ipad} {
        padding: 0 0 60px;
    }
`;

export const Title = styled.h1`
    ${Text.Full.heading2}
    color: ${cssVars.fg_black_white};
    width: 100%;
    padding: 0 0 17px;
    margin: 0;

    ${Screen.ipad} {
        ${Text.Ipad.heading3};
    }

    ${Screen.desktop} {
        padding: 0 0 27px;
    }
`;

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 20px;
`;

export const SpecialStatus = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: ${radius.small};
    margin: 20px 0 17px;
    ${Text.Mobile.heading4};
    background-color: ${cssVars.bg_sand_dark1};
    color: ${cssVars.fg_black_white};
    padding: 12px;

    ${Screen.ipad} {
        margin: 24px 0;
    }

    ${Screen.desktop} {
        max-width: 1064px;
        margin: 30px auto;
        ${Text.Desktop.heading2};
    }
`;

export const ActivityDescription = styled.div`
    width: 100%;
    margin: 20px 0 30px;

    ${Screen.ipad} {
        margin: 21px 0 37px;
    }

    ${Screen.desktop} {
        max-width: 1064px;
        margin: 15px 0 40px;
    }
`;

export const StyledAccordion = styled(Accordion)`
    width: 90%;
    margin: 0 5% 24px;
`;

export const FeaturedActivities = styled.div`
    ${Screen.desktop} {
        width: 1064px;
    }
`;

export const FeaturedActivitiesTitle = styled.h2`
    ${Text.Mobile.heading2};
    ${Screen.desktop} {
        ${Text.Desktop.heading2};
    }
`;

export const ActivityReviews = styled.div`
    padding: 0 0 50px;
    scroll-margin-top: 50px;
    > button {
        width: 95%;
        margin: 20px auto 0;
    }

    ${Screen.ipad} {
        padding: 0 0 60px;
        scroll-margin-top: 60px;
        > button {
            width: 90%;
            margin: 30px auto 0;
        }
    }

    ${Screen.desktop} {
        padding: 27px 0 60px;
        scroll-margin-top: 30px;
        > button {
            width: 250px;
            margin: 40px auto 0;
        }
    }
`;

export const ActivityReviewsTitle = styled.h2`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.heading2};
    margin: 22px 0 0;

    ${Screen.ipad} {
        margin: 27px 0 0;
    }
`;

export const ActivityReviewsSubTitle = styled.p`
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.large.medium};
    margin: 0;
`;

export const ReviewCardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    ${Screen.ipad} {
        gap: 24px;
        padding: 9px 0 0;
    }

    ${Screen.desktop} {
        gap: 24px;
    }
`;

export const NewToTS = styled.div`
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin: 25px 0 50px;
    ${Text.Mobile.heading4.bold}
    background-color: ${cssVars.bg_ash_dark3};
    color: ${cssVars.fg_gray2_gray3};

    ${Screen.ipad} {
        height: 90px;
        margin: 30px 0 60px;
        ${Text.Ipad.heading4}
    }
    ${Screen.desktop} {
        max-width: 1124px;
        padding: 0 30px;
        height: 110px;
        ${Text.Desktop.heading2}
        margin: 60px 0;
    }
`;

export const WrapperWithMargin = styled.div<{ isGiftCard: boolean }>`
    padding: ${({ isGiftCard }) => (isGiftCard ? '25px 0 30px' : '25px 0 5px')};

    ${Screen.ipad} {
        padding: ${({ isGiftCard }) => (isGiftCard ? '30px 0 60px' : '30px 0 10px')};
    }

    ${Screen.desktop} {
        padding: ${({ isGiftCard }) => (isGiftCard ? '27px 0 60px' : '27px 0 5px')};
    }
`;

export const DetailsContainer = styled.div`
    margin: 30px 18px;
    max-width: 1124px;
    ${Screen.ipad} {
        margin: 40px 30px;
        padding: 0 30px;
    }
    ${Screen.desktop} {
        margin: 50px auto;
    }
`;

export const Partner = styled.div`
    display: flex;
    ${Text.Full.large.regular};
    color: ${cssVars.fg_gray2_gray3};
    margin: 10px 0;

    ${Screen.desktop} {
        margin: 15px 0 10px;
    }
`;

export const PartnerLink = styled.a`
    ${Text.Full.large.regular};
    text-decoration: underline;
    color: ${Palette.state.info};
`;

export const Duration = styled.span`
    display: flex;
    align-items: center;
    ${Text.Full.large.regular};
    color: ${cssVars.fg_gray2_gray3};
    margin: 10px 0;
`;
