import { cssVars } from '@/styles/Themes/cssVars';
import Text from '@/styles/Themes/Text';
import styled from 'styled-components';
import Screen from '@/styles/Themes/Screen';
import { Palette } from '@/styles/Themes/Palette';

export const StiсkyFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    height: 70px;
    background-color: ${Palette.blue.normal};
    border-radius: 15px 15px 0px 0px;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: ${cssVars.shadow_card_default};
    z-index: 3;

    ${Screen.ipad} {
        height: 65px;
    }

    ${Screen.desktop} {
        height: 80px;
    }
`;

export const StiсkyFooterContent = styled.div<{ isInView: boolean }>`
    display: flex;
    position: absolute;
    bottom: 0;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    height: 100%;
    transform: ${({ isInView }) => (isInView ? 'translateY(-100%)' : 'translateY(0%)')};
    transition: 0.3s all;

    ${Screen.desktop} {
        max-width: 1064px;
    }
`;

export const SecondStiсkyFooterContent = styled.div<{ isInView: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    height: 100%;
    transform: ${({ isInView }) => (!isInView ? 'translateY(100%)' : 'translateY(0%)')};
    transition: 0.3s all;

    ${Screen.desktop} {
        max-width: 1064px;
    }
`;

export const RatingSection = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    max-width: 60%;
    color: ${Palette.white.normal};
    ${Text.Full.large.medium};
    min-height: 48px;

    > a {
        display: block;
        ${Text.Mobile.large.medium};
        text-decoration: underline;
    }

    ${Screen.ipad} {
        justify-content: center;
        > a {
            ${Text.Ipad.medium.medium}
        }
    }
    ${Screen.desktop} {
        > a {
            ${Text.Desktop.large.medium}
        }

        > div {
            margin: 5px 0;
        }
    }
`;

export const ReviewsOrNew = styled.div`
    cursor: pointer;
    ${Text.Mobile.normal.medium};
    text-decoration: underline;

    ${Screen.ipad} {
        ${Text.Ipad.medium.medium};
    }
    ${Screen.desktop} {
        ${Text.Desktop.large.medium};
    }
`;

export const PriceSection = styled.div`
    display: none;

    ${Screen.ipad} {
        display: flex;
        align-items: center;
        justify-content: end;
        color: ${Palette.white.normal};
        ${Text.Full.large.medium};
        margin: 0 0 0 50px;
        width: 40%;
    }

    ${Screen.desktop} {
        width: 60%;
    }
`;

export const OldPrice = styled.span`
    ${Text.Full.large.medium};
    text-decoration: line-through;
`;

export const NewPrice = styled.span`
    ${Text.Mobile.heading4.bold};

    ${Screen.ipad} {
        ${Text.Full.heading3}
    }

    ${Screen.desktop} {
        ${Text.Desktop.heading2};
    }
`;

export const ButtonSectionFirst = styled.div<{ isValid: boolean; isInView: boolean }>`
    display: flex;
    align-items: center;
    height: 36px;
    width: 50%;

    > button {
        ${Text.Full.large.medium};
        padding: 0;
        box-shadow: ${({ isInView, isValid }) => isInView && !isValid && 'none'};
    }

    ${Screen.ipad} {
        width: 30%;
        ${Text.Ipad.large.medium};
    }

    ${Screen.desktop} {
        width: 20%;
        height: 46px;
        ${Text.Desktop.medium.medium};
    }
`;

export const ButtonSection = styled.div`
    display: flex;
    align-items: center;
    height: 36px;
    width: 43%;

    > button {
        ${Text.Full.large.medium};
        padding: 0;
    }

    ${Screen.ipad} {
        width: 30%;
        > button {
            ${Text.Ipad.large.medium};
        }
    }

    ${Screen.desktop} {
        width: 20%;
        > button {
            height: 46px;
            ${Text.Desktop.large.medium};
        }
    }
`;

export const StatusMessage = styled.div`
    cursor: pointer;
    ${Text.Full.large.medium};
    text-decoration: underline;
`;

export const PriceMobile = styled.div`
    ${Screen.ipad} {
        display: none;
    }
`;
