import { memo } from 'react';
import { isProd } from '@/common/app/constants/envs';
import { CommonMeta } from '@/common/ui/meta/CommonMeta';

type ActivityPageProps = {
    pageMeta: {
        title: string;
        description: string;
    };
    pageActivity: {
        previewDescription: string;
        name: string;
        url: string;
    };
    pageStructuredData: string;
    openGraphImage: string;
    rootUrl: string;
    noindex?: boolean;
};

const ActivityPageMeta = ({
    pageMeta,
    pageActivity,
    pageStructuredData,
    openGraphImage,
    noindex,
    rootUrl,
}: ActivityPageProps) => {
    return (
        <CommonMeta
            title={pageMeta.title}
            description={pageMeta.description}
            slug=""
            canonical={rootUrl}
            ogTitle={pageActivity.name}
            ogDescription={pageActivity.previewDescription}
            imageSrc={openGraphImage}
            ogImageWidth="1080"
            ogImageHeight="1080"
            fbAppId={isProd ? '1639232852890435' : '3049823561722669'}
            ogType="website"
            ogUrl={rootUrl}
            structuredData={[pageStructuredData]}
            noIndex={noindex}
        />
    );
};

export default memo(ActivityPageMeta);
