import { Accordion, IAccordionTab } from '@/common/ui/containers/Accordion/Accordion';
import { LocationMap } from '@/common/ui/maps/LocationMap/LocationMap';
import * as S from './ActivityTabs.styles';
import { ACTIVITY_TABS } from './activityTabs/constants';

type ActivityTabsProps = {
    tabs: IAccordionTab[];
    latitude?: string;
    longitude?: string;
    address?: string;
    handleClickOnTab: (value: number) => void;
    indexOpen: number | null;
};

export const ActivityTabs = ({
    tabs,
    latitude,
    longitude,
    address,
    handleClickOnTab,
    indexOpen,
}: ActivityTabsProps) => {
    const addLocationTab = (
        prevTabs: IAccordionTab[],
        address?: string,
        latitude?: string,
        longitude?: string
    ) => {
        if (address || (latitude && longitude)) {
            return [
                ...prevTabs,
                {
                    title: ACTIVITY_TABS.find((tab) => tab.key === 'location')?.title || '',
                    id: '',
                    content: (
                        <>
                            {latitude && longitude && (
                                <S.LocationStyle>
                                    <LocationMap
                                        latitude={latitude}
                                        longitude={longitude}
                                        address={address}
                                    />
                                </S.LocationStyle>
                            )}
                        </>
                    ),
                },
            ];
        }
        return prevTabs;
    };

    return (
        <Accordion
            tabs={addLocationTab(tabs, address, latitude, longitude)}
            handleClickOnTab={handleClickOnTab}
            indexOpen={indexOpen}
            withStars
        />
    );
};
