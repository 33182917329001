import { ReactElement, memo } from 'react';
import * as S from './SoldoutDay.styles';
import Link from 'next/link';

type SoldoutDayProps = {
    selectedDateTitle: string;
    nextAvailDateTitle: string;
    onClickNextAvail: () => void;
    linkToCategory: string;
};

export const SoldoutDayContainer = ({
    selectedDateTitle,
    nextAvailDateTitle,
    onClickNextAvail,
    linkToCategory,
}: SoldoutDayProps): ReactElement => {
    return (
        <S.Container>
            <S.DesktopText>
                This activity is not available on <S.BoldText>{selectedDateTitle}</S.BoldText>.
                Please choose another day or{' '}
                <Link href={linkToCategory.toLowerCase()} passHref>
                    <S.LinkText>book a similar activity.</S.LinkText>
                </Link>{' '}
                You may also start a live chat with one of our live agents for recommendations.
            </S.DesktopText>
            <S.MobileText>
                <S.MobileTitle>
                    Sold out {selectedDateTitle.substring(selectedDateTitle.indexOf(' '))}
                </S.MobileTitle>
                Don&apos;t worry, you can...
                <br />
                Choose another day above
                <br />
                <Link href={linkToCategory.toLowerCase()} passHref>
                    <S.LinkText>Book a similar activity</S.LinkText>
                </Link>
                <br />
                <S.LinkText>Live Chat</S.LinkText> with one of our agents
            </S.MobileText>
            {!!nextAvailDateTitle && (
                <S.NextAvail onClick={onClickNextAvail}>
                    Next available date is <S.LinkText>{nextAvailDateTitle}</S.LinkText>
                </S.NextAvail>
            )}
        </S.Container>
    );
};

export const SoldoutDay = memo(SoldoutDayContainer);
