import { Rating } from '@/entities/Activity/ui/Rating';
import { TRatingStars } from '@/common/domain/Rating.domain';
import * as S from './ActivitySubtitle.styles';
import { TooltipOnClick } from '@/common/ui/containers/TooltipOnClick/TooltipOnClick';
import { numberFormatPrice } from '@/common/app/utils/numberUtils';
import { useTalkToUs } from '@/shared/TalkToUs/service/TalkToUs.service';

type ActivitySubtitleProps = {
    destination: { name: string; code: string };
    scrollToReviews: () => void;
    ratings: TRatingStars;
    reviewsCount: number;
    ratingsUrl: string;
    onTalkToUsClick: () => void;
    isGiftCard: boolean;
    isTour?: boolean;
};

export const ActivitySubtitle = ({
    destination,
    ratings,
    reviewsCount,
    onTalkToUsClick,
    scrollToReviews,
    isGiftCard,
    isTour,
}: ActivitySubtitleProps) => {
    const { name, code } = destination;

    const { isValidTime } = useTalkToUs();
    const reviewsCountWithComma = numberFormatPrice(reviewsCount);

    return (
        <S.ActivitySubtitleContainer data-test-id="Activity_subtitle">
            <S.LeftSection>
                {!isGiftCard && (
                    <S.Location>
                        <S.LocationIcon />
                        {`${name}, ${code}`}
                    </S.Location>
                )}
                {!isGiftCard && (
                    <>
                        {reviewsCount > 0 && (
                            <S.Ratings onClick={scrollToReviews}>
                                <Rating rating={ratings} />
                                <S.ReviewsCountLink>
                                    {reviewsCountWithComma
                                        ? reviewsCountWithComma === '0'
                                            ? ' '
                                            : reviewsCountWithComma === '1'
                                            ? reviewsCountWithComma + ' rating'
                                            : reviewsCountWithComma + ' ratings'
                                        : null}
                                </S.ReviewsCountLink>
                            </S.Ratings>
                        )}
                        {reviewsCount === 0 && (
                            <TooltipOnClick
                                description={
                                    'Once your trip is completed, you can tell us about your experience'
                                }
                            >
                                <S.BeFirst>Be the first to review!</S.BeFirst>
                            </TooltipOnClick>
                        )}
                    </>
                )}
            </S.LeftSection>
            <S.RightSection>
                {isTour ? (
                    <S.TalkToUsLink
                        onClick={(e) => {
                            if (!isValidTime()) {
                                e.preventDefault();
                                e.stopPropagation();
                                onTalkToUsClick();
                            }
                        }}
                        data-test-id="Talktous_button"
                        href="tel:+18504245125"
                    >
                        <S.TalkToUsIcon />
                        &nbsp; 850-424-5125
                    </S.TalkToUsLink>
                ) : (
                    <S.TalkToUsButton
                        variant="transparent"
                        onClick={onTalkToUsClick}
                        data-test-id="Talktous_button"
                    >
                        <S.TalkToUsIcon />
                        &nbsp; Talk to us
                    </S.TalkToUsButton>
                )}
            </S.RightSection>
        </S.ActivitySubtitleContainer>
    );
};
