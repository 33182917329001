import styled from 'styled-components';
import Screen from '@/styles/Themes/Screen';
import Palette from '@/styles/Themes/Palette';
import { radius } from '@/styles/Themes/Metrics';
import { Transition } from '@/styles/Themes/KeyFrames';
import Text from '@/styles/Themes/Text';

export const Container = styled.div`
    position: relative;
    cursor: pointer;
`;

export const Title = styled.div`
    color: ${Palette.white.normal};
    background: ${Palette.gradient.light};
    border-radius: ${radius.small};
    padding: 12px 14px;
    ${Text.Full.normal.medium};
    position: absolute;
    left: 88px;
    bottom: calc(100% + 5px);
    z-index: 1;
    transition: ${Transition.normal};
    width: 250px;

    ${Screen.desktop} {
        padding: 12px 20px;
        left: 139px;
        width: 360px;
    }

    &:before {
        content: '';
        height: 14px;
        width: 14px;
        background: ${Palette.green.normal};
        transform: rotate(45deg);
        position: absolute;
        bottom: -5px;
        left: 30px;
        border-radius: 3px;
    }
`;
