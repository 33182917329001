import { ReactElement, memo } from 'react';
import * as S from './CalendarBody.styles';
import { useActivityContext } from '@/common/app/contexts/ActivityContext';
import { format, isAfter, parseISO, startOfDay, startOfToday } from 'date-fns';
import { Schedule } from '@/common/service/api/Availability/Availability.domain';
import RadioOptions from './RadioOptions/RadioOptions';
import { Button } from '@/common/ui';
import { Spinner } from '@/common/ui/loaders/Spinner';
import RadioButton from '@/common/ui/inputs/RadioButton/RadioButton';
import { Checkbox } from '@/common/ui/inputs/Checkbox/Checkbox';
import { Dimmer } from '@/common/ui/loaders/Dimmer/Dimmer';
import { SoldoutDay } from './SoldoutDay/SoldoutDay';
import { Tooltip } from '@/common/ui/containers/Tooltip/Tooltip';
import { processTicketsInventories } from '@/screens/ActivityPage/service/processTicketsInventories';
import { CreateYourBooking } from './CreateYourBooking/CreateYourBooking';
import { selectedData } from '@/entities/Activity/app/Creators';
import Link from 'next/link';
import { AdditionalOptions } from './AdditionalOptions/AdditionalOptions';
import { RatesWrapper } from './RatesWrapper/RatesWrapper';
import { IBreadcrumbsItem } from '@/shared/Breadcrumbs/domain/Breadcrumbs.domain';
import { MainErrorMessage } from '../MainErrorMessage/MainErrorMessage';
import { createStepNumbers } from './TicketLabel/Creators';
import { PaymentBlock } from './PaymentBlock/PaymentBlock';

type CalendarBodyProps = {
    isGiftCard: boolean;
    handleClickOnTab: (value: number) => void;
    lastBreadcrumb: IBreadcrumbsItem[];
    reservationIndex: number;
};

/* eslint-disable sonarjs/cognitive-complexity */
export const CalendarBody = ({
    isGiftCard,
    handleClickOnTab,
    lastBreadcrumb,
    reservationIndex,
}: CalendarBodyProps): ReactElement => {
    const {
        activity,
        selected,
        availability,
        minMaxPrices,
        status,
        selectTicket,
        selectOption,
        changeComment,
        submitAddToCart,
        selectPolicy,
        selectAgreement,
        changeDay,
        selectRate,
    } = useActivityContext();

    if (!availability.days_info) {
        return <></>;
    }

    const isAvailableSchedule = !!+availability.use_schedule;

    const { selectedDay, selectedRate, selectedSchedule } = selectedData(availability, selected);

    const finalTickets = processTicketsInventories(selected.tickets, selectedSchedule);

    const agreement = JSON.parse(activity.data)?.text.agreement;

    const dateTitle = format(
        startOfDay(parseISO(selectedDay?.date ?? startOfToday().toISOString())),
        'EEEE MMMM dd, yyyy'
    );

    const priceStatus =
        (minMaxPrices[0] &&
            minMaxPrices[0] !== minMaxPrices[1] &&
            ((minMaxPrices[0] === selectedDay?.min_price && 'best price') ||
                (minMaxPrices[1] === selectedDay?.min_price && 'high demand'))) ||
        '';

    const useTripflex = Boolean(+activity?.use_tripflex);

    const TripflexTipContent = (
        <>
            <S.TipTitle>
                Cancel before your arrival time for ANY reason for a full refund*
            </S.TipTitle>
            <S.TipText>
                Reasons might include unfavorable weather, illness or injury, family emergency,
                heavy traffic or car trouble, or you just change your mind.
                <br />
                <br />
                *The TripFlex fee is non-refundable
            </S.TipText>
        </>
    );

    const onClickTicket = (ticketId: number) => (index: number) => {
        selectTicket(ticketId, ++index);
    };

    if (!selectedDay?.is_avail) {
        const nextAvailDay = availability.days_info.filter(
            (day) => day.is_avail && isAfter(new Date(day.date), new Date(selectedDay?.date || 0))
        );

        const nextAvailDateTitle = nextAvailDay.length
            ? format(
                  startOfDay(parseISO(nextAvailDay[0].date ?? startOfToday().toISOString())),
                  'EEEE MMM dd, yyyy'
              )
            : '';

        const linkToCategory = lastBreadcrumb[0].url;

        return (
            <SoldoutDay
                selectedDateTitle={dateTitle}
                nextAvailDateTitle={nextAvailDateTitle}
                onClickNextAvail={changeDay(nextAvailDay[0]?.id)}
                linkToCategory={linkToCategory || ''} // need improve ???
            />
        );
    }

    const stepNumbers = createStepNumbers(
        availability,
        isAvailableSchedule,
        finalTickets,
        activity,
        agreement
    );

    if (!availability.days_info) {
        return <></>;
    }

    const schedules =
        activity.id === '1453'
            ? [{ ...selectedRate?.schedules[0], name: 'All day admission' }]
            : selectedRate?.schedules;

    return (
        <Dimmer isLoading={status.isLoading}>
            <S.Container data-test-id="calendar-body" id="calendar-body" isGiftCard={isGiftCard}>
                {!!+availability.use_calendar && (
                    <S.Title>
                        {dateTitle}
                        {!!priceStatus && (
                            <S.Status isRed={priceStatus === 'high demand'}>{priceStatus}</S.Status>
                        )}
                    </S.Title>
                )}
                <RatesWrapper
                    rates={selectedDay.rates}
                    selectedId={selected.rate_id}
                    selectRate={selectRate}
                >
                    {!!+availability.use_schedule && !!schedules?.length && (
                        <S.FirstStepWrapper>
                            <S.Subtitle data-test-id="choose-a-time">
                                {stepNumbers[0]}. Choose a time
                            </S.Subtitle>
                            <RadioOptions
                                schedules={schedules as Schedule[]}
                                selectedId={selected.schedule_id}
                            />
                        </S.FirstStepWrapper>
                    )}
                    <div>
                        <CreateYourBooking
                            selectedSchedule={selectedSchedule}
                            status={status}
                            tickets={finalTickets}
                            selected={selected}
                            needTicketsGuests={Boolean(+activity.need_tickets_guests)}
                            onClickTicket={onClickTicket}
                            stepNumbers={stepNumbers}
                            isGiftCard={isGiftCard}
                        />
                    </div>
                    <div>
                        <AdditionalOptions
                            selected={selected}
                            selectedRate={selectedRate}
                            selectOption={selectOption}
                        />
                    </div>
                </RatesWrapper>
                {((activity.policy_content && activity.policy_title) || !!agreement) && (
                    <div>
                        <S.Subtitle id="cancellation-info">
                            {stepNumbers[2]}. {useTripflex ? 'Select Policy' : 'Review policy'}
                        </S.Subtitle>
                        <S.PolicyGrid>
                            <RadioButton
                                checked={selected.add_tripflex === 0}
                                onClick={() => selectPolicy(0)}
                                name="policy"
                            >
                                <Tooltip description={activity.policy_content} inCart={false}>
                                    <S.PolicyText onClick={() => selectPolicy(0)}>
                                        {activity.policy_title}
                                    </S.PolicyText>
                                </Tooltip>
                            </RadioButton>
                            {useTripflex &&
                                selectedSchedule?.tickets?.length &&
                                !(status.tripflexValue < 0) && (
                                    <RadioButton
                                        checked={selected.add_tripflex === 1}
                                        onClick={() => selectPolicy(1)}
                                        name="policy"
                                    >
                                        <Tooltip description={TripflexTipContent} inCart={false}>
                                            <S.PolicyText onClick={() => selectPolicy(1)}>
                                                TripFlex - Last minute cancellation coverage (Highly
                                                Recommended) - ${`${status.tripflexValue}`}
                                                <br />
                                            </S.PolicyText>
                                            <S.TripflexOver>{status.tripflexText}</S.TripflexOver>
                                        </Tooltip>
                                    </RadioButton>
                                )}
                        </S.PolicyGrid>
                    </div>
                )}
                <div>
                    <S.Subtitle data-test-id="reservation-comments">
                        {stepNumbers[3]}. Reservation comments
                    </S.Subtitle>
                    <S.TextArea
                        value={selected.comment_guest}
                        placeholder="optional comments"
                        onChange={(event) => changeComment(event.target.value)}
                        data-test-id="comment-area"
                    />
                    {!!agreement && (
                        <Checkbox
                            onChange={selectAgreement}
                            checked={!!selected.agreement_confirm}
                            id="cancellation-terms"
                        >
                            I agree to the&nbsp;
                            <S.BlueText onClick={() => handleClickOnTab(reservationIndex)}>
                                <Link href={'#additional'} scroll={true}>
                                    reservation requirements.
                                </Link>
                            </S.BlueText>
                        </Checkbox>
                    )}
                    {status.error && <MainErrorMessage message={status.message} />}
                </div>
                <S.BtnWrapper>
                    <Button
                        onClick={submitAddToCart}
                        variant="primary"
                        disabled={!status.isValid || status.isLoading}
                    >
                        {status.isLoading ? (
                            <Spinner isLoading />
                        ) : (
                            (status.editModeID && 'Edit tickets') || 'Add to cart'
                        )}
                    </Button>
                </S.BtnWrapper>
                <PaymentBlock />
            </S.Container>
        </Dimmer>
    );
};

export default memo(CalendarBody);
