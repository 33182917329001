import { ReactElement } from 'react';
import * as S from './PaymentBlock.styles';

import { Mastercard } from '@/shared/images/PaymentIcons/Mastercard';
// import { Paypal } from '@/shared/images/PaymentIcons/Paypal';
// import { PaypalCredit } from '@/shared/images/PaymentIcons/PaypalCredit';
import { Visa } from '@/shared/images/PaymentIcons/Visa';
import { Americanexpress } from '@/shared/images/PaymentIcons/Americanexpress';
import { Googlewallet } from '@/shared/images/PaymentIcons/Googlewallet';
import { Applepay } from '@/shared/images/PaymentIcons/Applepay';
import { Discover } from '@/shared/images/PaymentIcons/Discover';
// import { IfFeatureEnabled } from '@growthbook/growthbook-react';

export const PaymentBlock = (): ReactElement => {
    return (
        <S.PaymentBlock>
            <S.PaymentText>Flexible Payment Options</S.PaymentText>
            <S.PaymentIcons>
                <Mastercard />
                {/* <IfFeatureEnabled feature="paypal-on">
                    <Paypal />
                    <PaypalCredit />
                </IfFeatureEnabled> */}
                <Visa />
                <Americanexpress />
                <Googlewallet />
                <Applepay />
                <Discover />
            </S.PaymentIcons>
        </S.PaymentBlock>
    );
};
