import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';

export const Subtitle = styled.span`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.large.semibold}
    padding: 30px 0 20px;
    display: block;

    ${Screen.ipad} {
        padding: 30px 0 24px;
    }

    ${Screen.desktop} {
        padding: 30px 0 13px;
    }
`;

export const OptionGrid = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 16px;

    ${Screen.ipad} {
        padding: 0 20px;
    }

    ${Screen.desktop} {
        max-width: 50%;
        padding-left: 30px;
    }
`;

export const OptionText = styled.span`
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.medium.regular}
    display: flex;
    margin: 0 0 4px 18px;
`;

export const Required = styled.span`
    color: ${Palette.state.error};
    margin-right: 8px;
`;

export const Price = styled.div`
    color: ${Palette.green.normal};
    ${Text.Mobile.large.medium};
    ${Text.Full.medium.medium}
    white-space: nowrap;
    &:first-letter {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
    }
`;
