import styled from 'styled-components';
import Screen from '@/styles/Themes/Screen';
import { radius } from '@/styles/Themes/Metrics';

export const Container = styled.div`
    width: 90%;
    margin: 25px 5%;

    ${Screen.desktop} {
        width: 1124px;
        margin: auto;
        padding: 30px;
    }
`;

export const LocationStyle = styled.div`
    > iframe {
        width: 100%;
        border-radius: ${radius.small};
        margin: 9px 0 7px;
    }

    ${Screen.desktop} {
        > iframe {
            margin: 16px 0 7px;
        }
    }
`;
