import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { borders } from '@/styles/Themes/Metrics';

export const PaymentBlock = styled.div`
    border-top: ${borders.small} ${cssVars.bg_gray4_dark2};
`;

export const PaymentText = styled.span`
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.medium.regular}
    display: block;
    padding: 17px 0;

    ${Screen.ipad} {
        padding: 24px 0;
    }

    ${Screen.desktop} {
        padding: 30px 0;
    }
`;

export const PaymentIcons = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
    gap: 12px;

    ${Screen.ipad} {
        gap: 16px;
    }

    > svg {
        width: 67px;
        height: 40px;
    }

    ${Screen.desktop} {
        width: 70%;
        justify-content: space-between;
    }
`;
