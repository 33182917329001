export const RedHeartIcon = (props: { className?: string }) => {
    return (
        <svg
            className={`HeartIcon ${props.className}`}
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.44847 18C9.27972 18 9.19534 17.9164 9.02658 17.8328C8.26718 17.2474 2.02321 12.063 0.926296 9.80522C-0.592508 6.46041 -0.254996 3.28284 2.02321 1.27595C3.71077 -0.312831 7.25465 -0.563691 9.3641 1.35957C9.44847 1.44319 9.53285 1.44319 9.61723 1.35957C11.7267 -0.563691 15.2706 -0.312831 16.9581 1.27595C19.2363 3.36646 19.6582 6.62765 17.9706 9.88884C16.6206 12.5647 10.1235 17.6655 9.87036 17.9164C9.70161 17.9164 9.61723 18 9.44847 18ZM5.56708 1.19233C4.47017 1.19233 3.45764 1.52681 2.86699 2.11216C1.01067 3.78456 0.757539 6.37679 2.10759 9.21988C2.86699 10.8087 7.33902 14.7388 9.44847 16.4948C10.8829 15.3242 15.7768 11.3104 16.7894 9.21988C18.2238 6.46041 17.9706 3.86818 16.03 2.19578C14.933 1.19233 12.1486 0.690612 10.3766 2.2794C9.87036 2.78112 8.94221 2.78112 8.43594 2.2794C7.67654 1.52681 6.57962 1.19233 5.56708 1.19233Z"
                fill="#EC4652"
            />
        </svg>
    );
};
