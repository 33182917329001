import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import Screen from '@/styles/Themes/Screen';
import { radius } from '@/styles/Themes/Metrics';

export const Title = styled.h2<{ isGiftCard: boolean }>`
    text-align: center;
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.heading2};
    padding: ${({ isGiftCard }) => (isGiftCard ? '12px 0 16px' : '12px 0 0')};
    margin: 0;

    ${Screen.ipad} {
        padding: ${({ isGiftCard }) => (isGiftCard ? '47px 0 21px' : '41px 0 0')};
    }

    ${Screen.desktop} {
        padding: ${({ isGiftCard }) => (isGiftCard ? '47px 0 21px' : '47px 0 0')};
    }
`;

export const CaledarWrapper = styled.div`
    margin: 0 -16px;
    width: calc(100% + 32px);

    ${Screen.ipad} {
        box-shadow: ${cssVars.shadow_card_default};
        border-radius: ${radius.small};
        width: 100%;
        margin: 0;
    }
`;

export const AvaiCalendar = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.large.medium};
    padding: 0 0 17px;
    text-align: center;

    ${Screen.ipad} {
        padding: 0 0 9px;
    }

    ${Screen.desktop} {
        padding: 0 0 21px;
    }
`;
